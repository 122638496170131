import { Box, Flex } from "@chakra-ui/react";
import WelcomeSection from "components/WelcomeSection";
import { Helmet } from "react-helmet";
import MemberDashboardSection from "./MemberDashboardSection";

export default function MembersPage() {
  return (
    <>
      <Helmet>
        <title>Product List - Origin360 Dashboard</title>
        <meta
          name="description"
          content="Browse and manage your product listings on the Origin360 dashboard. Filter, search, and update product statuses to keep your inventory organized and accessible."
        />
      </Helmet>
      <Box bg="white" w="100%">
        <Box>
          <Flex>
            <Flex gap="18px" bg="very_light_gray" flex={1} flexDirection="column" py="20px" minHeight={"100vh"}>
              {/* welcome section */}
              <WelcomeSection />

              {/* product dashboard section */}
              <MemberDashboardSection />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
