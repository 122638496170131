import { Center } from '@chakra-ui/react';
import { useAuthentication } from "hooks/useAuthentication";
import { useCallback, useEffect } from "react";
import { Navigate } from "react-router-dom";

type GuardProps = {
  children: JSX.Element | JSX.Element[];
  allowRoles?: string[];
};

export function Authenticated({ children, allowRoles }: GuardProps) {
  const { user } = useAuthentication();
  if (user) {
    return <>{children}</>;
  }

  return (
    <Center>
      Non autorisé <a href="/">cliquez ici pour continuer</a>
    </Center>
  );
}
export function UnAuthenticated({ children, allowRoles }: GuardProps) {
  const { user } = useAuthentication();
  if (user) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}

export function AnyUserWithPermission({ children, allowRoles }: GuardProps) {
  const { loading, hasRole } = useAuthentication();

  if (loading) {
    return <Center>Loading...</Center>;
  }

  if (allowRoles?.some(hasRole)) {
    return <>{children}</>;
  }

  return (
    <Center>
      Non autorisé <a href="/">cliquez ici pour continuer</a>
    </Center>
  );
}

export function PropertyOwnerOnlyGuard({ children, allowRoles }: GuardProps) {
  const {
    user: { user, auth } = {},
    authProvider: { signOut },
  } = useAuthentication();

  const checkAccessTokenExpiration = useCallback(async () => {
    const expirationDate = new Date(auth?.tokenExpirationDate as string);
    const now = new Date();
    if (!user || now > expirationDate) {
      await signOut();
    }
  }, [signOut, auth, user]);

  useEffect(() => {
    checkAccessTokenExpiration();
  }, [checkAccessTokenExpiration]);
  // console.log(allowRoles?.some(hasRole));
  // IMPORTANT: Customer user account should not be able to access this page
  const userIsCustomer = user && user?.id;
  if (
    userIsCustomer &&
    allowRoles?.some((item) => user?.roles.includes(item))
  ) {
    return <>{children}</>;
  }

  return (
    <Center>
      Non autorisé <a href="/">cliquez ici pour continuer</a>
    </Center>
  );
}

export function AdminOnlyGuard({ children, allowRoles }: GuardProps) {
  const {
    user: { user, auth } = {},
    authProvider: { signOut },
  } = useAuthentication();
  console.log(user);

  const checkAccessTokenExpiration = useCallback(async () => {
    const expirationDate = new Date(auth?.tokenExpirationDate as string);
    const now = new Date();
    if (!user || now > expirationDate) {
      await signOut();
    }
  }, [signOut, auth, user]);

  useEffect(() => {
    checkAccessTokenExpiration();
  }, [checkAccessTokenExpiration]);
  // console.log(allowRoles?.some(hasRole));
  // IMPORTANT: Customer user account should not be able to access this page
  const userIsCustomer = user && user?.id;
  if (
    userIsCustomer &&
    allowRoles?.some((item) => user?.roles.includes(item))
  ) {
    return <>{children}</>;
  }

  return (
    <Center>
      Non autorisé <a href="/">cliquez ici pour continuer</a>
    </Center>
  );
}
