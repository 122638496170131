import { ToastData } from "typing";
import { SecureStorageKeys } from "../store";
import { appLocalStorage } from "../utils/AppLocalStorage";

export const actions = {
  darkModeOn: "DARK_MODE_ON",
  darkModeOff: "DARK_MODE_OFF",
  showToast: "SHOW_TOAST",
  changeLanguage: "CHANGE_LANGUAGE",
  search: "SEARCH",
  filter: "FILTER",
  setActiveTab: "SET_ACTIVE_TAB",
  dashboard: "DASHBOARD",
};

export function activateDarkMode(darkMode: boolean) {
  appLocalStorage.setItem(SecureStorageKeys.darkMode, "ON");
  return {
    type: actions.darkModeOn,
    payload: darkMode,
  };
}

export function deActivateDarkMode(darkMode: boolean) {
  appLocalStorage.setItem(SecureStorageKeys.darkMode, "OFF");
  return {
    type: actions.darkModeOff,
    payload: darkMode,
  };
}

export function showToast(data: ToastData) {
  return {
    type: actions.showToast,
    payload: data,
  };
}

export function changeLanguage(language: string) {
  appLocalStorage.setItem(SecureStorageKeys.language, language);
  return {
    type: actions.changeLanguage,
    payload: language,
  };
}

export function search(searchKeyword: string) {
  return {
    type: actions.search,
    payload: searchKeyword,
  };
}

export function filter(data: any) {
  return {
    type: actions.filter,
    payload: data,
  };
}

export function setActiveTab(tab: number) {
  return {
    type: actions.setActiveTab,
    payload: tab,
  };
}

export function dashboard(dashboard: any) {
  return {
    type: actions.dashboard,
    payload: dashboard,
  };
}
