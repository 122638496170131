import { ChevronDownIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import AppSelect from "components/AppSelect";
import AppViewDrawer from "components/AppViewDrawer";
import AppViewListItem from "components/AppViewListItem";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useCallback, useContext, useMemo, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Store } from "store";
import { showToast, toastTypes } from "utils";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  company_name: string;
  name: string;
  is_available: boolean;
  created_at: string;
  image: string;
  company_logo: string;
  subcategory_name: string;
  meta_data: any;
};

export default function ProductDashboardSection() {
  const location = useLocation();
  const {startDate, endDate} = location.state as any || {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeItem, setActiveItem] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const { hasPermission, user } = useAuthentication();
  // const [searchBarValue34, setSearchBarValue34] = useState("");
  const [company, setCompany] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { getProducts, updateProduct, getCompanies, getSubCategories } =
    useApi();
  const { data: products, mutate } = useCache("products", () =>
    getProducts(page, "", company, category, startDate, endDate)
  );
  const {
    data: companies,
    mutate: mutateCompanies,
    isLoading: isCompanyLoading,
  } = useCache("companies", () => getCompanies(page));
  const {
    data: categories,
    mutate: mutateCategories,
    isLoading: isCategoryLoading,
  } = useCache("categories", () => getSubCategories(page));
  // const handleSearch = (event: any) => {
  //   const value = event.target.value;
  //   if (event.key === "Enter") {
  //     setSearchBarValue34(value);
  //     mutate(getProducts(page, value));
  //   } else {
  //     setSearchBarValue34(value);
  //   }
  // };
  // const handleClearSearch = (event: any) => {
  //   setSearchBarValue34("");
  //   mutate(getProducts(page, ""));
  // };

  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(getProducts(pg));
  };
  const handleSetNextPage = () => {
    if (products.length > 0 && products[0].num_of_pages >= page + 1) {
      setPage((prev: any) => prev + 1);
      mutate(getProducts(page + 1));
    }
  };
  const handleSetPrevPage = () => {
    if (products.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(getProducts(page + 1));
    }
  };

  const handleViewItem = useCallback(
    (item: any) => {
      setActiveItem(item);
      onOpen();
    },
    [onOpen, setActiveItem]
  );
  const handleUpdateAvailability = useCallback(
    async (product: any) => {
      const response = await updateProduct({
        ...product,
        is_available: product?.is_available ? false : true,
      });
      if (response.id) {
        dispatch(
          showToast({
            toast: toast,
            message: "produit mis à jour avec succès",
            status: toastTypes.success,
          })
        );
        mutate(getProducts(page, ""));
      }
    },
    [
      dispatch,
      getProducts,
      mutate,
      page,
      toast,
      updateProduct,
    ]
  );
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("company_name", {
        cell: (info) => (
          <Flex justifyContent="center">
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="0px">
              {/* <Checkbox value="true" /> */}
              <Image
                src={info.row.original.company_logo as unknown as string}
                alt="Client Image"
                h="32px"
                w="32px"
                fit="contain"
                borderRadius="10px"
              />
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="12px">
            <Flex
              gap="8px"
              w={{ md: "42%", base: "100%" }}
              alignItems="center"
              px="12px"
            >
              {/* <Checkbox value="true" /> */}
              <Heading as="h6">Client</Heading>
            </Flex>
          </Flex>
        ),
        meta: { width: "170px" },
      }),
      table3ColumnHelper.accessor("name", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
            <Image
              src={info.row.original.image as unknown as string}
              alt="Client Image"
              h="32px"
              w="32px"
              fit="contain"
              borderRadius="10px"
            />
            <Text>{info.getValue<string>()}</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">Produit</Heading>
          </Flex>
        ),
        meta: { width: "212px" },
      }),
      table3ColumnHelper.accessor("subcategory_name", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
            <Text>{info.getValue<string>()}</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">Catégorie</Heading>
          </Flex>
        ),
        meta: { width: "212px" },
      }),
      table3ColumnHelper.accessor("created_at", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px="25px" py="14px">
            <Heading as="h6">Date et heure</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("is_available", {
        cell: (info) => (
          <Flex px={{ base: "20px", sm: "24px" }}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size={"sm"}
                colorScheme={
                  info.getValue<boolean>() ? "green_360" : "red_origin"
                }
                borderRadius={"4px"}
                px={"5px"}
                fontSize={"10px"}
              >
                {info.getValue<boolean>() ? "Active" : "Inactive"}
              </MenuButton>
              {user?.user?.roles === "admin" &&
                hasPermission("product", "update") && (
                  <MenuList>
                    <MenuItem
                      onClick={() =>
                        handleUpdateAvailability(info.row.original)
                      }
                    >
                      <span>
                        {info.getValue<boolean>() ? "Désactiver" : "Activer"}
                      </span>
                    </MenuItem>
                  </MenuList>
                )}
            </Menu>
          </Flex>
        ),
        header: (info) => (
          <Flex py="14px" px="40px">
            <Heading as="h6">État</Heading>
          </Flex>
        ),
        meta: { width: "132px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"} width={"100%"} justifyContent={"flex-end"}>
            {hasPermission("product", "update") && (
              <IconButton
                variant="outline"
                colorScheme="gray_stroke_0"
                aria-label="Edit"
                size={"sm"}
                icon={<EditIcon />}
                borderRadius={"4px"}
                onClick={() => navigate(`edit/${info.getValue()}`)}
              />
            )}
            {hasPermission("product", "get") && (
              <IconButton
                variant="outline"
                colorScheme="gray_stroke_0"
                aria-label="view"
                size={"sm"}
                icon={<ViewIcon />}
                borderRadius={"4px"}
                onClick={() => handleViewItem(info.row.original)}
              />
            )}
          </HStack>
        ),
        header: (info) => (
          <Flex justifyContent={"flex-end"} px="15px" py="14px">
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [
    navigate,
    handleViewItem,
    hasPermission,
    handleUpdateAvailability,
    user?.user?.roles,
  ]);
  console.log(category, company)
  return (
    <>
      {/* client management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
              Produits
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            >
              {hasPermission("product", "create") && (
                <Button
                  size="xl"
                  variant="outline"
                  colorScheme="red_origin"
                  leftIcon={
                    <Image
                      src="images/img_handshake_deal_svgrepo_com_red_origin.svg"
                      alt="Handshake-deal Svgrepo.com"
                    />
                  }
                  fontWeight={700}
                  gap="8px"
                  minW="128px"
                  borderRadius="8px"
                  as={Link}
                  href="/products/add"
                >
                  Créer un produit
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
              gap={3}
            >
              {/* <InputGroup w={{ base: "100%", sm: "20%" }}>
                <InputLeftElement>
                  <Image
                    src="images/img_search_black.svg"
                    alt="Search"
                    w="16px"
                    h="14px"
                  />
                </InputLeftElement>
                <Input
                  placeholder={`Rechercher`}
                  value={searchBarValue34}
                  onKeyUp={handleSearch}
                  onChange={handleSearch}
                  gap="8px"
                  borderWidth="1px"
                  borderRadius="8px"
                />
                <InputRightElement>
                  {searchBarValue34?.length > 0 ? (
                    <CloseIcon onClick={handleClearSearch} />
                  ) : null}
                </InputRightElement>
              </InputGroup> */}
              <Box w={{ base: "100%", sm: "20%" }}>
                <AppSelect
                  onChange={(field: string, value: any) => {
                    setCompany(value);
                    mutate(
                      getProducts(page, "", value, category, startDate, endDate)
                    );
                  }}
                  value={company}
                  placeholder="client"
                  options={companies}
                  isMulti={false}
                  isLoading={isCompanyLoading}
                  id="company"
                  name="company"
                  handleInputChange={(inputVal) => {
                    mutateCompanies(getCompanies(1, inputVal));
                  }}
                />
              </Box>
              <Box w={{ base: "100%", sm: "20%" }}>
                <AppSelect
                  onChange={(field: string, value: any) => {
                    setCategory(value);
                    mutate(
                      getProducts(page, "", company, value, startDate, endDate)
                    );
                  }}
                  value={category}
                  placeholder="catégorie"
                  options={categories}
                  isMulti={false}
                  isLoading={isCategoryLoading}
                  id="category"
                  name="category"
                  handleInputChange={(inputVal) => {
                    mutateCategories(getSubCategories(1, inputVal));
                  }}
                />
              </Box>
              {/* <Box>
              <IconButton
                size="3xl"
                colorScheme="red_origin"
                icon={<SearchIcon />}
                aria-label="281:4184-Mute Button"
                w="52px"
                borderRadius="5px"
                onClick={() => {
                  mutate(getProducts(page, searchBarValue34, company, category));
                }}
              />
              </Box> */}
            </Flex>
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={products}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={
              products && products?.length > 0 && products[0].num_of_pages
            }
          />
        </Box>
      </Flex>
      <AppViewDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <DrawerHeader>Fiche Produit</DrawerHeader>

        <DrawerBody>
          <VStack alignItems={"flex-start"} gap={"15px"}>
            <HStack>
              <Button
                size="xl"
                variant="outline"
                colorScheme="green_360"
                leftIcon={<EditIcon />}
                fontWeight={700}
                gap="2px"
                borderRadius="8px"
                as={Link}
                href={`/products/edit/${activeItem?.id}`}
              >
                Modifier
              </Button>
            </HStack>
            <VStack alignItems={"center"} gap={"5px"} width={"100%"}>
              <Flex gap="8px" padding={"1px"}>
                <Zoom>
                  <Image
                    src={activeItem?.image}
                    fallbackSrc="/images/dummy.png"
                    alt="logo Image"
                    h="100px"
                    w="100px"
                    fit="contain"
                    borderRadius="16px"
                  />
                </Zoom>
              </Flex>
            </VStack>
            <AppViewListItem label="Produit" value={activeItem?.name} />
            <AppViewListItem label="Client" value={activeItem?.company_name} />
            <AppViewListItem
              label="Catégorie"
              value={activeItem?.subcategory_name}
            />
            <AppViewListItem
              label="Description"
              value={activeItem?.description}
            />

            {activeItem?.meta_data &&
              JSON.parse(activeItem?.meta_data)?.map((item: any) => (
                <AppViewListItem
                  key={item.id}
                  label={item.label}
                  value={item.fieldValue as string}
                />
              ))}
          </VStack>
        </DrawerBody>
      </AppViewDrawer>
    </>
  );
}
