import { useCallback, useEffect, useRef } from 'react';

const useAutoLogout = (logout, timeout = 300000) => {
  const timeoutId = useRef(null); // To store the timeout ID

  const resetTimeout = useCallback(() => {
    // Clear the existing timeout
    if (timeoutId.current) clearTimeout(timeoutId.current);

    // Start a new timeout
    timeoutId.current = setTimeout(logout, timeout);  // timeout is in milliseconds
  },[logout, timeout]);

  useEffect(() => {
    // Events to track user activity
    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    // Add event listeners for user activity
    events.forEach(event => window.addEventListener(event, resetTimeout));

    // Set the initial timeout
    resetTimeout();

    return () => {
      // Clean up: remove event listeners and clear timeout on component unmount
      events.forEach(event => window.removeEventListener(event, resetTimeout));
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [logout, timeout, resetTimeout]);

  return null;
};

export default useAutoLogout;
