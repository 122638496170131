import { Box, Flex, Image } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import ForgotPassword from "./ForgotPassword";

export default function ForgotPasswordPage() {
  return (
    <>
      <Helmet>
        <title>Forgot Password - Connect to Origin360</title>
        <meta
          name="description"
          content="Access your Origin360 account by logging in. Enter your email and password to continue to your dashboard. Secure and reliable connection."
        />
      </Helmet>
      <Box h="1024px" bg="very_light_gray" w="100%" position="relative">
        <Image
          src="images/img_logo_origin_360.png"
          alt="Logo Image"
          h="584px"
          w="38%"
          fit="contain"
          position="absolute"
          bottom="0px"
          left="0px"
          m="auto"
        />
        <Flex
          gap={{ md: "126px", base: "63px", sm: "94px" }}
          flex={1}
          flexDirection="column"
          position="absolute"
          left="0px"
          bottom="0px"
          right="0px"
          top="0px"
          h="max-content"
        >
          {/* welcome section */}
          <ForgotPassword />
        </Flex>
      </Box>
    </>
  );
}
