import { Box, Button, Container, Flex, FormControl, Heading, Image, Text, useToast } from "@chakra-ui/react";

import { useAuthentication } from "hooks/useAuthentication";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Store } from "store";

// third party
import AppInput from "components/AppInput";
import { useFormik } from "formik";
import { showToast, toastTypes } from "utils";
import * as Yup from "yup";

export default function WelcomeSection() {
  const { token } = useParams();
  const toast = useToast();
  const { dispatch } = useContext(Store);
  const {
    authProvider: { setNewPassword },
  } = useAuthentication();
  const initialValues = {
    passwordConfirm: "",
    password: "",
    submit: null,
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string().min(8).max(255).required("Le mot de passe est requis").matches(
      /(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
      "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
    ),
    passwordConfirm: Yup.string()
      .min(8, "Mot de passe trop court")
      .required("Confirmer le mot de passe est requis").matches(
        /(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
      ).oneOf([Yup.ref("password")], "Les mots de passe ne correspondent pas"),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ passwordConfirm, password }) => {
      try {
        const response: any = await setNewPassword({
          password,
          token: token as string,
        });
        if (response.ok) {
          dispatch(
            showToast({
              toast: toast,
              message: "Réinitialisation du mot de passe terminée",
              status: toastTypes.success,
              description:"Vous pouvez maintenant vous connecter avec votre nouveau mot de passe"
            })
          );
          resetForm()
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: "Impossible de réinitialiser le mot de passe",
              status: toastTypes.error,
            })
          );
        }
      } catch (ex) {
        dispatch(
          showToast({
            toast: toast,
            message: "Impossible de réinitialiser le mot de passe",
            status: toastTypes.error,
          })
        );
        setFieldValue("password", "");
      }
    },
  });
  return (
    <>
      {/* welcome section */}
      <Flex flexDirection="column" alignItems="center" mt={10}>
        <Container
          gap={{ md: "20px", base: "88px", sm: "132px" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={{ md: "56px", base: "20px" }}
          p={{ md: 0, base: "20px" }}
        >
          <Flex
            w={{ md: "86%", base: "100%" }}
            justifyContent="center"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Box
              w={{ md: "46%", base: "100%" }}
              display={{ md: "flex", base: "none" }}
            >
              <Flex
                borderTopLeftRadius="24px"
                borderBottomLeftRadius="24px"
                gap="20px"
                bg="red_origin"
                flexDirection="column"
                px={{ base: "20px", sm: "32px" }}
              >
                <Flex
                   mt="20px"
                   gap="16px"
                   flexDirection="column"
                   alignItems="start"
                >
                  <Text size="text3xl" color="white" textAlign={"center"}>
                  Bienvenue sur ORIGIN360
                  </Text>
                  <Text
                    size="textxl"
                    color="very_light_gray"
                    w="100%"
                    lineHeight="150%"
                    textAlign={"center"}
                  >
                    La plateforme de protection des marques par la vérification de l’authenticité d’articles et documents administratifs.
                  </Text>
                </Flex>
                <Box bg={"white"} h="200px"
                  w="200px" borderRadius={"25px"} mx={"auto"}>
                <Image
                  src="images/qr-code-login-1.png"
                  alt="Decorative Image"
                  h="200px"
                  w="200px"
                  fit="contain"
                  mx={"auto"}
                />
                </Box>
              </Flex>
            </Box>
            <Flex
              flex={1}
              flexDirection="column"
              alignItems="start"
              alignSelf={{ md: "auto", base: "stretch" }}
            >
              <form
                onSubmit={handleSubmit}
                style={{ flex: 1, alignSelf: "stretch" }}
              >
                <Flex
                  borderTopRightRadius="24px"
                  borderBottomRightRadius="24px"
                  gap="42px"
                  bg="white"
                  w={{ md: "88%", base: "100%" }}
                  flexDirection="column"
                  alignItems="center"
                  p={{ base: "20px", sm: "22px" }}
                  height={"100%"}
                >
                  <Flex
                    gap="2px"
                    w={{ md: "90%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Image
                      src="images/logo.png"
                      alt="Login Image"
                      h="112px"
                      w="112px"
                      fit="contain"
                      mx={"auto"}
                    />
                    <Flex flexDirection="column" alignItems="start">
                      <Heading size="headingxl" as="h1">
                      Réinitialiser le mot de passe
                      </Heading>
                      <Text size="textxl" color="gray_description">
                      Entrez votre nouveau mot de passe
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    gap="8px"
                    w={{ md: "90%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Flex gap="12px" flexDirection="column">
                      <FormControl
                        isInvalid={!!errors.passwordConfirm && touched.passwordConfirm}
                      >
                        <AppInput
                          required
                          placeholder={"*********"}
                          gap="8px"
                          type="password"
                          borderWidth="1px"
                          alignSelf="stretch"
                          borderRadius="8px"
                          id="passwordConfirm"
                          name="passwordConfirm"
                          autoComplete="passwordConfirm"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.passwordConfirm || ""}
                          showErrorMessage={Boolean(
                            touched.passwordConfirm && errors.passwordConfirm
                          )}
                          errorMessage={errors.passwordConfirm}
                        />
                      </FormControl>
                      <FormControl
                        isInvalid={!!errors.password && touched.password}
                      >
                        <AppInput
                          required
                          placeholder={`*********`}
                          type="password"
                          fontFamily="Arimo"
                          gap="8px"
                          borderWidth="1px"
                          alignSelf="stretch"
                          borderRadius="8px"
                          name="password"
                          autoComplete="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password || ""}
                          showErrorMessage={Boolean(
                            touched.password && errors.password
                          )}
                          errorMessage={errors.password}
                        />
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex
                    mb="46px"
                    gap="12px"
                    w={{ md: "34%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Button
                      size="4xl"
                      colorScheme="red_origin"
                      fontWeight={700}
                      boxShadow="md"
                      alignSelf="stretch"
                      borderRadius="8px"
                      type="submit"
                      fontSize={"15px"}
                      width={"180px"}
                      isLoading={isSubmitting}
                    >
                      Changer mot de passe
                    </Button>
                    <a href="/">
                      <Button
                        size="2xl"
                        colorScheme="gray_stroke_0"
                        fontWeight={700}
                        boxShadow="md"
                        alignSelf="stretch"
                        mx={{ md: "14px", base: "0px" }}
                        borderRadius="8px"
                      >
                        Se connecter
                      </Button>
                    </a>
                  </Flex>
                </Flex>
              </form>
            </Flex>
          </Flex>
          <Text
            size="texts"
            color="gray_description"
            fontFamily="Arimo"
            fontWeight={500}
          >
            Origin360. Copyrights ©️ 2024
          </Text>
        </Container>
      </Flex>
    </>
  );
}
