import { Text, VStack } from "@chakra-ui/react";

type AppViewListItemProps = {
  label: string;
  value: any;
};

export default function AppViewListItem({
  label,
  value,
}: AppViewListItemProps) {
  return (
    <>
      <VStack alignItems={"flex-start"} gap={"5px"}>
        <Text
          color={"gray_description"}
          fontSize={"10px"}
          fontWeight={"bolder"}
        >
          {label}
        </Text>
        {<Text>{value}</Text>}
      </VStack>
    </>
  );
}
