import { Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay } from "@chakra-ui/react";

type AppViewDrawerProps = {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    children: JSX.Element[]
}

export default function AppViewDrawer({ isOpen, onOpen, onClose, children }:AppViewDrawerProps) {
    return (
      <>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent css={{ borderTopLeftRadius: '16px' }}>
            <DrawerCloseButton />
            <>
           {children}
            </>
          </DrawerContent>
        </Drawer>
      </>
    )
  }