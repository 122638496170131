import { CloseIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  group_name: string;
  alias: string;
  permissions:any[];
  actionHeader: undefined;
};

export default function RoleManagementSection() {
  const navigate = useNavigate();
  const [searchBarValue34, setSearchBarValue34] = useState("");
  const { getRoles } = useApi();
  const {hasPermission} = useAuthentication()
  const {
    data: roles,
  } = useCache("roles", () => getRoles());
  const handleSearch = (event:any) => {
    setSearchBarValue34(event.target.value)
  }
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("group_name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text style={{textTransform:"capitalize"}}>{info.row.original.alias || info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1}  px={"25px"} py="14px">
            <Heading as="h6">Rôle</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("permissions", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px" flexWrap={"wrap"}>
              {
                info.getValue<any>()?.map((permission:any) => (
                  <Badge key={permission.id} fontSize={"12px"} style={{textTransform:"capitalize", fontWeight:"lighter"}}>{permission.name}</Badge>
                ))
              }
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1}  px={"25px"} py="14px">
            <Heading as="h6">Permissions</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"} width={"100%"} justifyContent={"flex-end"}>
            {hasPermission("role", "update") && <IconButton
              variant="outline"
              colorScheme="gray_stroke_0"
              aria-label="Edit"
              size={"sm"}
              icon={<EditIcon />}
              borderRadius={"4px"}
              onClick={() => navigate(`edit/${info.getValue()}`)}
            />}
          </HStack>
        ),
        header: (info) => (
          <Flex px="15px" py="14px" justifyContent={"flex-end"}>
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [navigate, hasPermission]);

  return (
    <>
      {/* dealer management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
            Rôle
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            >

              {hasPermission("role", "create") && <Button
                size="xl"
                variant="outline"
                colorScheme="red_origin"
                leftIcon={
                  <Image
                    src="images/img_handshake_deal_svgrepo_com_red_origin.svg"
                    alt="Handshake-deal Svgrepo.com"
                  />
                }
                fontWeight={700}
                gap="8px"
                minW="128px"
                borderRadius="8px"
                as={Link}
                href="/roles/add"
              >
                Créer un Rôle
              </Button>}
            </Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
            >
              <InputGroup w={{ base: "100%", sm: "30%" }}>
                <InputLeftElement>
                  <Image
                    src="images/img_search_black.svg"
                    alt="Search"
                    w="16px"
                    h="14px"
                  />
                </InputLeftElement>
                <Input
                  placeholder={`Rechercher`}
                  value={searchBarValue34}
                  onChange={handleSearch}
                  gap="8px"
                  borderWidth="1px"
                  borderRadius="8px"
                />
                <InputRightElement>
                  {searchBarValue34?.length > 0 ? (
                    <CloseIcon onClick={() => setSearchBarValue34("")} />
                  ) : null}
                </InputRightElement>
              </InputGroup>
                <IconButton onClick={handleSearch} ml={2} borderRadius={"10px"} py={"19px"} border={"1px solid #e2e4e6"} aria-label="search" icon={<SearchIcon/>} justifyContent={"center"} alignItems={"center"} />
            </Flex>
            <Image
              src="images/img_dot_menu.svg"
              alt="Menu Image"
              h="24px"
              w={{ md: "24px", base: "100%" }}
            />
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={roles}
          />
        </Box>
      </Flex>
    </>
  );
}
