import { Box, Button, Flex, FormControl, Heading, HStack, Text, useToast } from "@chakra-ui/react";

import { AppDropdownSelect } from "components/AppDropdownSelect";
import AppInput from "components/AppInput";
import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageMemberPage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const { user } = useAuthentication();
  const { getCompanies, uploadMemberCSV } = useApi();
  const { data: companies } = useCache("companies", () => getCompanies(1));

  const initialValues = useMemo(() => {
    return {
      description: "",
      company: user?.user.company_id || "",
      csv_file: null,
      submit: null,
    };
  }, [user?.user.company_id]);
  const validationSchema = Yup.object().shape({
    description: Yup.string().trim().required("Une description est requise"),
    company: Yup.string().trim().required("client est requise"),
    csv_file: Yup.mixed().notRequired(),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        let formData: any = new FormData();
        if (values.csv_file) {
          formData.append("csv_file", values.csv_file, values.csv_file.name);
        }
        formData.append("description", values.description);
        formData.append("company_id", user?.user.company_id || values.company);
        if (id) {
          formData.append("id", id);
        }
        let response: any = null;
        response = await uploadMemberCSV(formData);
        if (!response.error_code || response.errors) {
          dispatch(
            showToast({
              toast: toast,
              message: id
                ? "personne mis à jour avec succès"
                : "personne créé avec succès",
              status: toastTypes.success,
            })
          );
          if (id) {
            navigate("/documents");
          } else {
            navigate("/documents");
          }
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
          setFieldValue("password", "");
        }
      } catch (ex) {
        console.log(ex);
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
        setFieldValue("password", "");
      }
    },
  });
  const handleFileChange = async (event, imageType) => {
    const file = event.target.files[0];
    setFieldValue("csv_file", file);
  };
  return (
    <>
      {(
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
          minH={"100vh"}
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                Liste des personnes
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                ></Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="8px" flexDirection="column" alignItems="start">
                  <HStack>
                    <Text color="gray_description" mt="16px">
                    Fichier csv des personnes
                    </Text>
                    <span style={{ color: "red" }}>*</span>
                  </HStack>
                  <AppInput
                    type="file"
                    borderWidth="1px"
                    alignSelf="stretch"
                    borderRadius="8px"
                    name="banner"
                    id="banner"
                    onChange={(e: any) => handleFileChange(e, "banner")}
                  />
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <HStack>
                    <Text color="gray_description">Nom du document</Text>
                    <span style={{ color: "red" }}>*</span>
                  </HStack>
                  <FormControl
                    isInvalid={
                      !!errors.description && (touched.description as boolean)
                    }
                  >
                    <AppInput
                      required
                      borderWidth="1px"
                      alignSelf="stretch"
                      borderRadius="8px"
                      id="description"
                      name="description"
                      autoComplete="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description || initialValues.description}
                      showErrorMessage={Boolean(touched.description && errors.description)}
                      errorMessage={errors.description}
                    />
                  </FormControl>
                </Flex>
                {!user?.user.company_id && (
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <HStack>
                      <Text color="gray_description">Client</Text>
                      <span style={{ color: "red" }}>*</span>
                    </HStack>
                    <Flex
                      gap="4px"
                      flexDirection="column"
                      alignItems="start"
                      width={"100%"}
                    >
                      <AppDropdownSelect
                        name="company"
                        data={companies}
                        value={values.company as string}
                        onChange={setFieldValue}
                        placeholder="Veuillez sélectionner un client"

                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex mt="24px" gap="12px">
                <Button
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                  onClick={() => window.history.back()}
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}
