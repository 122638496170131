import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Heading, IconButton, Image, Text, useToast } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import QrCodePrint from "components/AppQrCodePdfPrint";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Store } from "store";
import { closeLoader, openLoader, showToast, toastTypes } from "utils";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  index: number;
  id: number;
  verification_token: string;
  created_at: string;
  has_used: boolean;
  used_by: string;
  product: any;
};

export default function CodeDashboardSection() {
  const { requestId } = useParams();
  const loadingRef = useRef<any>(null);
  const { dispatch } = useContext(Store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const componentRef = useRef<any>();
  const toast = useToast();
  const {hasPermission} = useAuthentication()
  const { getQrCodes, updateQrCode } = useApi();
  const { data: qrCodes, mutate } = useCache("qrCodes", () => getQrCodes(page, +requestId));
  const [verificationToken, setVerificationToken] = useState<any>(null)

  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(getQrCodes(pg, +requestId));
  };
  const handleSetNextPage = () => {
    if (qrCodes.length > 0 && qrCodes[0].num_of_pages >= page + 1) {
      setPage((prev: any) => prev + 1);
      mutate(getQrCodes(page + 1, +requestId));
    }
  };
  const handleSetPrevPage = () => {
    if (qrCodes.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(getQrCodes(page + 1, +requestId));
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const markQrCodeAsRead = useCallback(
    async (item: any) => {
      openLoader(loadingRef);
      const response = await updateQrCode({id:item.id, qr_code_request_id: +requestId, has_used:true });
      if (response?.id) {
        dispatch(
          showToast({
            toast: toast,
            message: "Le code Qr a été marqué comme lu",
            status: toastTypes.success,
          })
        );
      }else{
        dispatch(
          showToast({
            toast: toast,
            message: "Impossible d'effectuer l'action",
            status: toastTypes.error,
          })
        );
      }
      mutate(getQrCodes(page, +requestId))
      closeLoader(loadingRef)
    },
    [dispatch, updateQrCode, getQrCodes, mutate, page, toast, requestId]
  );
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("index", {
        cell: (info) => (
          <Flex justifyContent="center">
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="0px">
              {/* <Checkbox value="true" /> */}
              <Text>{info.row.index + 1}</Text>
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="12px">
            <Flex
              gap="8px"
              w={{ md: "42%", base: "100%" }}
              alignItems="center"
              px="12px"
            >
              {/* <Checkbox value="true" /> */}
              <Heading as="h6">ID</Heading>
            </Flex>
          </Flex>
        ),
        meta: { width: "30px" },
      }),
      table3ColumnHelper.accessor("verification_token", {
        cell: (info) => (
          <Flex justifyContent="center">
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="0px">
              {/* <Checkbox value="true" /> */}
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="12px">
            <Flex
              gap="8px"
              w={{ md: "42%", base: "100%" }}
              alignItems="center"
              px="12px"
            >
              {/* <Checkbox value="true" /> */}
              <Heading as="h6">ID Codes QR</Heading>
            </Flex>
          </Flex>
        ),
        meta: { width: "270px" },
      }),
      table3ColumnHelper.accessor("created_at", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
            <Text>{info.getValue<string>()}</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">Date</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("product", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
             <Text>{info.row.original.product.name}</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">Produit</Heading>
          </Flex>
        ),
        meta: { width: "300px" },
      }),
      table3ColumnHelper.accessor("has_used", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
            <Text sx={{color: info.getValue<boolean>() ? 'green_360' : 'red_origin'}}>{info.getValue<boolean>() ? 'Utilisée': 'Non utilisé' }</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">État</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("used_by", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
             <Text>{info.getValue<string>()}</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">Utilisé par</Heading>
          </Flex>
        ),
        meta: { width: "150px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"} width={"100%"} justifyContent={"flex-end"}>
            {hasPermission("qrcodes", "update") &&
              !info.row.original.has_used && (
                <IconButton
                variant="outline"
                colorScheme="green_360"
                aria-label="view"
                size={"sm"}
                icon={<CheckIcon />}
                borderRadius={"4px"}
                onClick={() => markQrCodeAsRead(info.row.original)}
              />
              )}
            {hasPermission("qrcodes", "get")  &&  <IconButton
              variant="outline"
              colorScheme="red_origin"
              aria-label="print"
              size={"sm"}
              icon={
                <Image
                  src="images/pdf.svg"
                  alt="qr code icon"
                  width={3}
                  height={3}
                />
              }
              borderRadius={"4px"}
              onClick={() => {
                setVerificationToken(info.row.original.verification_token)
                handlePrint()
              }}
            />}
          </HStack>
        ),
        header: (info) => (
          <Flex justifyContent={"flex-end"} px="15px" py="14px">
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [hasPermission, handlePrint, markQrCodeAsRead]);

  return (
    <>
      {/* client management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
      >
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
            >
            </Flex>
            <Image
              src="images/img_dot_menu.svg"
              alt="Menu Image"
              h="24px"
              w={{ md: "24px", base: "100%" }}
            />
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={qrCodes}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={qrCodes && qrCodes?.length > 0 && qrCodes[0].num_of_pages}
          />
        </Box>
      </Flex>
      <Box display={"none"}>
        <QrCodePrint value={verificationToken} ref={componentRef} />
      </Box>
    </>
  );
}
