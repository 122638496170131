import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({ borderRadius: "0px", outlineOffset: "0", cursor: "pointer", flexDirection: "row" });

const sizes = {
  xs: defineStyle({
    h: "22px",
    fontSize: "10px",
    px: "6px",
  }),
  sm: defineStyle({
    h: "24px",
    px: "6px",
  }),
  "2xl": defineStyle({
    h: "36px",
    fontSize: "12px",
    px: "24px",
  }),
  "4xl": defineStyle({
    h: "42px",
    fontSize: "16px",
    px: "24px",
  }),
  "3xl": defineStyle({
    h: "40px",
    px: "10px",
  }),
  md: defineStyle({
    h: "24px",
    fontSize: "14px",
    px: "2px",
  }),
  xl: defineStyle({
    h: "32px",
    fontSize: "12px",
    px: "6px",
  }),
  lg: defineStyle({
    h: "32px",
    px: "8px",
  }),
};

const variants = {
  fill: defineStyle((props) => {
    const { colorScheme } = props;
    const colorCombinations = {
      red_origin: {
        bg: "red_origin",
        color: "white",
      },
      gray_stroke_0: {
        bg: "gray_stroke.0",
        color: "gray_description",
      },
      red_bg: {
        bg: "red_bg",
        color: "red_origin",
      },
      very_light_gray: {
        bg: "very_light_gray",
        color: "black",
      },
      gray_stroke_1: {
        bg: "gray_stroke.1",
      },
      white: {
        bg: "white",
      },
      green_360: {
        borderColor: "green_360",
        borderWidth: "1px",
        borderStyle: "solid",
        color: "green_360",
        bg:"green_360_ctg"
      }
    };
    return colorCombinations[colorScheme as keyof typeof colorCombinations] || colorCombinations["white"];
  }),
  outline: defineStyle((props) => {
    const { colorScheme } = props;
    const colorCombinations = {
      green_360: {
        borderColor: "green_360",
        borderWidth: "1px",
        borderStyle: "solid",
        color: "green_360",
      },
      red_origin: {
        borderColor: "red_origin",
        borderWidth: "1px",
        borderStyle: "solid",
        color: "red_origin",
      },
      gray_stroke_0: {
        borderColor: "gray_stroke.0",
        color: "gray_description",
        borderWidth: "1px",
        borderStyle: "solid",
      },
    };
    return colorCombinations[colorScheme as keyof typeof colorCombinations] || colorCombinations["green_360"];
  }),
};

const Button = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: "fill",
    size: "lg",
  },
});
export default Button;
