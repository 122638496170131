import { Flex } from "@chakra-ui/react";
import WelcomeSection from "components/WelcomeSection";
import RoleManagementSection from "./RoleManagementSection";

export default function RolePage() {
  return (
    <>
      <Flex
        gap="18px"
        bg="very_light_gray"
        flex={1}
        flexDirection="column"
        py="20px"
      >
        {/* welcome section */}
        <WelcomeSection />

        {/* client management section */}
        <RoleManagementSection />
      </Flex>
    </>
  );
}
