export const ADMIN = "admin";
export const COMPANY = "company";
export const COMPANY_ADMIN = "company admin";
export const DEALER = "dealer";

export const ROLES: any = {
  [ADMIN]: "Admin",
  [COMPANY]: "COMPANY",
  [COMPANY_ADMIN]: "COMPANY ADMIN",
  [DEALER]: "DEALER",
};

export const validRoles = [
  {name: "Admin", value: ADMIN},
  {name: "Company", value: COMPANY},
  {name: "Company Admin", value: COMPANY_ADMIN},
  {name: "Dealer", value: DEALER},
]


export const pageRoles = {
  superUser: [ADMIN],
  company: [COMPANY],
  companyAdmin: [COMPANY_ADMIN],
  dealer: [DEALER],
}
