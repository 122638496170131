import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  Image,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { loginSuccess } from "actions/authentication";
import AppInput from "components/AppInput";
import { PhoneNumberInput } from "components/PhoneNumberInput";
import WelcomeSection from "components/WelcomeSection";
import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ProfilePage() {
  const { user: sessionUser } = useAuthentication();
  const { user: authUser } = sessionUser;
  const { getUserProfile, updateUserProfile } = useApi();
  const [profilePictureSrc, setProfilePictureSrc] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const { data: user } = useCache("user", () =>
    getUserProfile(+authUser?.id || 0)
  );
  const initialValues = useMemo(() => {
    return {
      name: user?.name || "",
      email: user?.email || "",
      profilePicture: user?.profile_picture,
      phoneNumber: user?.phone_number || "",
      country: user?.country_id,
      password: "",
      passwordConfirm: "",
      updatePassword: false,
      submit: null,
    };
  }, [user]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Nom requis"),
    email: Yup.string().email(),
    phoneNumber: Yup.string()
      .trim()
      .required("le numéro de téléphone est requis"),
    country: Yup.string().trim().required("pays requis"),
    profilePicture: Yup.mixed(),
    password: Yup.string()
      .min(8)
      .max(255)
      .matches(
        /(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
      ),
    passwordConfirm: Yup.string()
      .min(8, "Mot de passe trop court")
      .matches(
        /(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
      )
      .oneOf([Yup.ref("password")], "Les mots de passe ne correspondent pas"),
    updatePassword: Yup.boolean().notRequired(),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const names = values.name.split(" ");
        const lastName = names[0]; // Get first name
        const firstName = names.slice(1).join(" "); // Get other names as a string
        let formData: any = new FormData();
        if (profilePictureSrc) {
          formData.append(
            "profile_picture",
            values.profilePicture,
            values.profilePicture.name
          );
        }
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("phone_number", values.phoneNumber);
        formData.append("country_id", values.country);
        formData.append("email", values.email);
        formData.append("password", values.password);
        if (authUser.id) {
          formData.append("id", authUser.id);
        }
        let response: any = null;
        if (authUser.id) {
          response = await updateUserProfile(formData);
        } else {
        }
        if (!response.error_code || response.errors) {
          dispatch(
            loginSuccess({ auth: { ...sessionUser.auth }, user: response })
          );
          dispatch(
            showToast({
              toast: toast,
              message: "Utilisateurs créé avec succès",
              status: toastTypes.success,
            })
          );
          navigate("/settings");
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
        }
      } catch (ex) {
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
      }
    },
  });
  const handleFileChange = async (event, imageType) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setProfilePictureSrc(url);
    setFieldValue("profilePicture", file);
  };
  return (
    <>
      <Flex
        gap="18px"
        bg="very_light_gray"
        flex={1}
        flexDirection="column"
        py="20px"
        minH={"100vh"}
      >
        {/* welcome section */}
        <WelcomeSection />

        {/* client management section */}
        <Card width={{ base: "80%", md: "30%" }} mx="auto">
          <CardBody>
            <Stack spacing="3">
              <Stack>
                <form
                  onSubmit={handleSubmit}
                  style={{
                    flexDirection: "column",
                    margin: "0px",
                    alignItems: "center",
                  }}
                >
                  <Flex flexDirection="column" alignItems="center">
                    <Flex
                      gap="16px"
                      w="100%"
                      flexDirection="column"
                      px={{ base: "20px", sm: "24px" }}
                      py={{ base: "20px", sm: "24px" }}
                      mx={{ md: "62px", base: "0px" }}
                      borderRadius="16px"
                    >
                      <Flex gap="16px" flexDirection="column">
                        <Flex
                          gap="8px"
                          flexDirection="column"
                          alignItems="start"
                        >
                          <AppInput
                            type="file"
                            borderWidth="1px"
                            alignSelf="stretch"
                            borderRadius="8px"
                            name="profilePicture"
                            id="profilePicture"
                            onChange={(e: any) =>
                              handleFileChange(e, "profilePicture")
                            }
                            display="none"
                          />
                          <Flex
                            gap="16px"
                            alignSelf="stretch"
                            alignItems="center"
                            justifyContent={"center"}
                            flexDirection={{ md: "row", base: "column" }}
                          >
                            <Image
                              src={
                                profilePictureSrc || user?.profile_picture
                                  ? profilePictureSrc || user?.profile_picture
                                  : "images/image_upload_preview.jpg"
                              }
                              alt="product Image"
                              h="100px"
                              alignSelf="center"
                              w={"100px"}
                              fit="contain"
                              borderRadius="50%"
                              onClick={() =>
                                document
                                  .getElementById("profilePicture")
                                  .click()
                              }
                            />
                          </Flex>
                          <Flex
                            gap="4px"
                            flexDirection="column"
                            alignItems="start"
                            width={"100%"}
                          >
                            <Text color="gray_description">Nom</Text>
                            <FormControl
                              isInvalid={
                                !!errors.name && (touched.name as boolean)
                              }
                            >
                              <AppInput
                                required
                                placeholder={`Nom`}
                                borderWidth="1px"
                                alignSelf="stretch"
                                borderRadius="8px"
                                id="name"
                                name="name"
                                autoComplete="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name || initialValues.name}
                                showErrorMessage={Boolean(
                                  touched.name && errors.name
                                )}
                                errorMessage={errors.name}
                              />
                            </FormControl>
                          </Flex>
                          <Flex
                            gap="4px"
                            flexDirection="column"
                            alignItems="start"
                            width={"100%"}
                          >
                            <Text color="gray_description">Email</Text>
                            <FormControl
                              isInvalid={
                                !!errors.email && (touched.email as boolean)
                              }
                            >
                              <AppInput
                                readOnly
                                placeholder={`Email`}
                                type="email"
                                borderWidth="1px"
                                alignSelf="stretch"
                                borderRadius="8px"
                                id="email"
                                name="email"
                                autoComplete="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email || initialValues.email}
                                showErrorMessage={Boolean(
                                  touched.email && errors.email
                                )}
                                errorMessage={errors.email}
                              />
                            </FormControl>
                          </Flex>
                          <Flex
                            gap="4px"
                            flexDirection="column"
                            alignItems="start"
                            width={"100%"}
                          >
                            <Text color="gray_description">
                              Numero de telephone
                            </Text>
                            <PhoneNumberInput
                              name="phoneNumber"
                              onChange={setFieldValue}
                              value={
                                values.phoneNumber || initialValues.phoneNumber
                              }
                              countryDialCode={user?.country_dial_code}
                              onBlur={handleBlur}
                              showErrorMessage={Boolean(
                                touched.phoneNumber && errors.phoneNumber
                              )}
                              errorMessage={errors.phoneNumber}
                            />
                          </Flex>
                          <Flex
                            gap="4px"
                            flexDirection="column"
                            alignItems="start"
                            width={"100%"}
                          >
                            <Checkbox
                              size="sm"
                              value="true"
                              color="gray_description"
                              fontSize="14px"
                              gap="8px"
                              isChecked={values.updatePassword ? true : false}
                              onChange={handleChange}
                              name="updatePassword"
                              id="updatePassword"
                            >
                              Mettre à jour le mot de passe
                            </Checkbox>
                          </Flex>
                          {values.updatePassword && (
                            <>
                              <Flex
                                gap="4px"
                                flexDirection="column"
                                alignItems="start"
                                width={"100%"}
                              >
                                <Text color="gray_description">Password</Text>
                                <FormControl
                                  isInvalid={
                                    !!errors.password &&
                                    (touched.password as boolean)
                                  }
                                >
                                  <AppInput
                                    placeholder={"*********"}
                                    gap="8px"
                                    type="password"
                                    borderWidth="1px"
                                    alignSelf="stretch"
                                    borderRadius="8px"
                                    id="password"
                                    name="password"
                                    autoComplete="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password || ""}
                                    showErrorMessage={Boolean(
                                      touched.password && errors.password
                                    )}
                                    errorMessage={errors.password}
                                  />
                                </FormControl>
                              </Flex>
                              <Flex
                                gap="4px"
                                flexDirection="column"
                                alignItems="start"
                                width={"100%"}
                              >
                                <Text color="gray_description">
                                  Confirmation du mot de passe
                                </Text>
                                <FormControl
                                  isInvalid={
                                    !!errors.passwordConfirm &&
                                    (touched.passwordConfirm as boolean)
                                  }
                                >
                                  <AppInput
                                    placeholder={"*********"}
                                    type="password"
                                    gap="8px"
                                    borderWidth="1px"
                                    alignSelf="stretch"
                                    borderRadius="8px"
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    autoComplete="passwordConfirm"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.passwordConfirm || ""}
                                    showErrorMessage={Boolean(
                                      touched.passwordConfirm &&
                                        errors.passwordConfirm
                                    )}
                                    errorMessage={errors.passwordConfirm}
                                  />
                                </FormControl>
                              </Flex>
                            </>
                          )}
                          <Flex mt="24px" gap="12px" width={"100%"}>
                            <Button
                              size="4xl"
                              width={"100%"}
                              colorScheme="red_origin"
                              fontFamily="Arimo"
                              fontWeight={700}
                              borderRadius="8px"
                              type="submit"
                              isLoading={isSubmitting}
                            >
                              Mise à jour
                            </Button>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </form>
              </Stack>
            </Stack>
          </CardBody>
          <Divider />
        </Card>
      </Flex>
    </>
  );
}
