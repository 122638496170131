import { EditIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  Heading,
  Image,
  Menu,
  MenuItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import WelcomeSection from "components/WelcomeSection";
import { useAuthentication } from "hooks/useAuthentication";
import { useNavigate } from "react-router-dom";
import { pageRoles } from "utils/roles";

const menuItems = [
  {
    label: "Catégories",
    link: "/categories",
    icon: (
      <Image
        src="images/category_icon.svg"
        alt="category Icon"
        h="16px"
        w="16px"
      />
    ),
    roles: pageRoles.superUser,
    model: "productcategory",
  },
  {
    label: "Sous-Catégories",
    link: "/sub-categories",
    icon: (
      <Image
        src="images/category_icon.svg"
        alt="category Icon"
        h="16px"
        w="16px"
      />
    ),
    roles: pageRoles.superUser,
    model: "subcategory",
  },
  {
    label: "Rôles",
    link: "/roles",
    icon: (
      <Image
        src="images/img_setting_3_svgrepo_com.svg"
        alt="Roles Icon"
        h="16px"
        w="16px"
      />
    ),
    roles: pageRoles.superUser,
    model: "role",
  },
  {
    label: "Signalements",
    link: "/complaints",
    icon: (
      <Image
        src="images/img_exclamation_cir.svg"
        alt="Reports Icon"
        h="16px"
        w="16px"
      />
    ),
    roles: pageRoles.superUser,
    model: "complaint",
  },
];

export default function SettingPage() {
  const { user, hasPermission } = useAuthentication();
  const navigate = useNavigate()
  return (
    <>
      <Flex
        gap="18px"
        bg="very_light_gray"
        flex={1}
        flexDirection="column"
        py="20px"
        minH={"100vh"}
      >
        {/* welcome section */}
        <WelcomeSection />

        {/* client management section */}
        <Card width={{base:"80%", md: "30%"}} mx="auto">
          <CardBody>
            <VStack justifyContent={"center"} alignItems={"center"}>
              <Avatar src={user?.user?.profile_picture || "images/img_ellipse_213.png"} h="70px" w="70px" />
              <VStack
                alignItems={"center"}
                justifyContent={"center"}
                // h="100px"
                gap={0.5}
              >
                <Heading textTransform={"uppercase"} fontSize={"16px"}>
                  {user?.user?.name}
                </Heading>
                <Text color={"gray_description"}>
                  {user?.user?.phone_number || "00-0000-0000"}
                </Text>
                <Text color={"gray_description"}>{user?.user?.email}</Text>
                <Button
                  size="3xl"
                  colorScheme="gray_description"
                  fontWeight={700}
                  boxShadow="md"
                  borderRadius="4px"
                  type="submit"
                  leftIcon={<EditIcon />}
                  fontSize={"10px"}
                  onClick={() => navigate("/profile/update")}
                >
                  Modifier
                </Button>
              </VStack>
            </VStack>
            <Stack mt="6" spacing="3">
              <Heading fontSize={"14px"}>Paramétres</Heading>
              <Box
                as={Menu}
                pr="6px"
                display="flex"
                alignSelf="stretch"
                flexDirection="column"
                w="100%"
                fontSize={"10px"}
              >
                {menuItems?.map(
                  (menu: any) =>
                    // menu?.roles.includes(user?.user?.roles) &&
                    hasPermission(menu.model, "get") && (
                      <MenuItem
                        icon={menu.icon}
                        as={"a"}
                        href={menu.link}
                        key={menu.label}
                        color={"gray_description"}
                        fontSize={"12px"}
                      >
                        {menu.label}
                      </MenuItem>
                    )
                )}
              </Box>
            </Stack>
          </CardBody>
          <Divider />
        </Card>
      </Flex>
    </>
  );
}
