import { Box, Flex } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Sidebar3 from "../../components/Sidebar3";

export default function MainLayout(props: any) {
  return (
    <>
      <Helmet>
        <title>Client Management - Origin360 User Engagement</title>
        <meta
          name="description"
          content="Manage your clients efficiently with Origin360's client management tools. Download client data, import new clients, and create detailed client profiles."
        />
      </Helmet>
      <Box bg="white" w="100%">
        <Box>
          <Flex>
            <Box height={"100vh"}>
              <Sidebar3 />
            </Box>
            <Box height={"100vh"} width={"100%"}>
              <Box width={"100%"}>
                {props.children ? <>{props.children}</> : <Outlet />}
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
