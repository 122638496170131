import { Box, Flex } from "@chakra-ui/react";
import WelcomeSection from "components/WelcomeSection";
import { Helmet } from "react-helmet";
import CodeDashboardSection from "./CodeDashboardSection";

export default function CodesPage() {
  return (
    <>
      <Helmet>
        <title>QrCode List - Origin360 Dashboard</title>
        <meta
          name="description"
          content="Browse and manage your product listings on the Origin360 dashboard. Filter, search, and update product statuses to keep your inventory organized and accessible."
        />
      </Helmet>
      <Box bg="white" w="100%">
        <Box>
          <Flex>
            <Flex gap="18px" bg="very_light_gray" flex={1} flexDirection="column" py="20px">
              {/* welcome section */}
              <WelcomeSection />

              {/* <AppQrCode value="https://github.com/gcoro/react-qrcode-logo"/> */}

              {/* product dashboard section */}
              <CodeDashboardSection />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
