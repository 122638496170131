const baseStyle = {
  color: "black",
  fontFamily: "Arimo",
};
const sizes = {
  bold_title_24: {
    fontSize: {
      md: "24px",
      base: "24px",
      sm: "22px",
    },
    fontWeight: 700,
    fontStyle: "bold",
  },
  title_black_12: {
    fontSize: "12px",
    fontWeight: 700,
    fontStyle: "bold",
  },
  button_text_bold_14: {
    fontSize: "14px",
    fontWeight: 700,
    fontStyle: "bold",
  },
  textxs: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "bold",
  },
  textlg: {
    fontSize: "16px",
    fontWeight: 500,
    fontStyle: "bold",
  },
  text2xl: {
    fontSize: {
      md: "24px",
      base: "24px",
      sm: "22px",
    },
    fontWeight: 500,
    fontStyle: "bold",
  },
  headingxs: {
    fontSize: "10px",
    fontWeight: 700,
    fontStyle: "bold",
  },
  headings: {
    fontSize: "14px",
    fontWeight: 600,
  },
  headingmd: {
    fontSize: "20px",
    fontWeight: 600,
    fontStyle: "bold",
  },
  headinglg: {
    fontSize: {
      md: "24px",
      base: "24px",
      sm: "22px",
    },
    fontWeight: 600,
    fontStyle: "bold",
  },
  headingxl: {
    fontSize: {
      md: "30px",
      base: "26px",
      sm: "28px",
    },
    fontWeight: 700,
    fontStyle: "bold",
  },
  heading2xl: {
    fontSize: {
      md: "32px",
      base: "28px",
      sm: "30px",
    },
    fontWeight: 700,
    fontStyle: "bold",
  },
};
const defaultProps = {
  size: "title_black_12",
};

const Heading = {
  baseStyle,
  sizes,
  defaultProps,
};
export default Heading;
