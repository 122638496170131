import {
  Flex,
  FormErrorMessage,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";

const AppNumberInput = (props: any) => {
  const { label, showErrorMessage, errorMessage, sx, ...restProps } = props;
  return (
    <>
      <Flex gap="4px" flexDirection="column" alignItems="start" width={"100%"}>
        <Text>{label} {restProps.required && <span style={{color:"red"}}>*</span>}</Text>
        <NumberInput min={1} _focus={{borderColor:"white", boxShadow:"0px"}} size="md" width={"100%"} {...restProps}>
          <NumberInputField _focus={{borderColor:"white", boxShadow:"0px"}} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Flex>
      {showErrorMessage && (
        <FormErrorMessage style={{ color: "red" }}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </>
  );
};

export default AppNumberInput;
