import { AgCharts } from "ag-charts-react";

function AppChart(props) {
  const {data, title} = props;
  const chartOptions:any = {
    // Data: Data to be displayed in the chart
    title: {
        text: title,
      },
    data: data,
    // Series: Defines which chart type and data to use
    series: [
      {
        type: "bar",
        xKey: "month",
        yKey: "qrCodeRequests",
        fill: "#e33621",
        fillOpacity: "#e33621",
        cornerRadius: 10,
        showInMiniChart:false,
        highlightStyle: {
          item: {
            fill: "#e33621",
            stroke: "#e33621",
          },
        },
      },
    ],
  };

  return (
    // AgCharts component with options passed as prop
    <AgCharts options={chartOptions} />
  );
}

export default AppChart;
