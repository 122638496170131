import { Box } from "@chakra-ui/react";
import AppQrCode from "components/AppQrCode";
import { forwardRef } from "react";

  
  const QrCodePrint = forwardRef((props: any, ref: any) => {
    const {value} = props
    return (
      <>
      <style type="text/css" media="print">
        {/* @media print{@page {size: landscape}} */}
      </style>
        <Box
          className="printDiv"
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
            <AppQrCode value={value}/>
        </Box>
    </>
    );
  });
  
  export default QrCodePrint;
  