import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Link,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import AppSelect from "components/AppSelect";
import AppViewDrawer from "components/AppViewDrawer";
import AppViewListItem from "components/AppViewListItem";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useCallback, useMemo, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  company_name: string;
  description: string;
  created_at: string;
  company_logo: string;
  meta_data: any;
};

export default function MemberDashboardSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeItem, setActiveItem] = useState(null);
  const { hasPermission } = useAuthentication();
  // const [searchBarValue34, setSearchBarValue34] = useState("");
  const [company, setCompany] = useState<any>("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { getMembers, getCompanies } =
    useApi();
  const { data: members, mutate } = useCache("members", () =>
    getMembers(page, "", company, "")
  );
  const {
    data: companies,
    mutate: mutateCompanies,
    isLoading: isCompanyLoading,
  } = useCache("companies", () => getCompanies(page));

  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(getMembers(pg));
  };
  const handleSetNextPage = () => {
    if (members.length > 0 && members[0].num_of_pages >= page + 1) {
      setPage((prev: any) => prev + 1);
      mutate(getMembers(page + 1));
    }
  };
  const handleSetPrevPage = () => {
    if (members.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(getMembers(page + 1));
    }
  };

  const handleViewItem = useCallback(
    (item: any) => {
      setActiveItem(item);
      onOpen();
    },
    [onOpen, setActiveItem]
  );
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("company_name", {
        cell: (info) => (
          <Flex justifyContent="center">
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="0px">
              {/* <Checkbox value="true" /> */}
              <Image
                src={info.row.original.company_logo as unknown as string}
                alt="Client Image"
                h="32px"
                w="32px"
                fit="contain"
                borderRadius="10px"
              />
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="12px">
            <Flex
              gap="8px"
              w={{ md: "42%", base: "100%" }}
              alignItems="center"
              px="12px"
            >
              {/* <Checkbox value="true" /> */}
              <Heading as="h6">Client</Heading>
            </Flex>
          </Flex>
        ),
        meta: { width: "170px" },
      }),
      table3ColumnHelper.accessor("description", {
        cell: (info) => (
          // <Box flex={1}>
          <Flex gap="8px" alignItems="center" px="12px" py="0px">
            <Text>{info.getValue<string>()}</Text>
          </Flex>
          // </Box>
        ),
        header: (info) => (
          <Flex
            gap="8px"
            w={{ md: "42%", base: "100%" }}
            alignItems="center"
            px="25px"
          >
            <Heading as="h6">Nom du document</Heading>
          </Flex>
        ),
        meta: { width: "212px" },
      }),
      table3ColumnHelper.accessor("created_at", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px="25px" py="14px">
            <Heading as="h6">Date</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"} width={"100%"} justifyContent={"flex-end"}>
            {hasPermission("member", "get") && (
              <IconButton
                variant="outline"
                colorScheme="gray_stroke_0"
                aria-label="view"
                size={"sm"}
                icon={<ViewIcon />}
                borderRadius={"4px"}
                onClick={() => handleViewItem(info.row.original)}
              />
            )}
          </HStack>
        ),
        header: (info) => (
          <Flex justifyContent={"flex-end"} px="15px" py="14px">
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [
    handleViewItem,
    hasPermission,
  ]);
  return (
    <>
      {/* client management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
              Documents
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            >
              {hasPermission("member", "create") && (
                <Button
                  size="xl"
                  variant="outline"
                  colorScheme="red_origin"
                  leftIcon={
                    <Image
                      src="images/img_handshake_deal_svgrepo_com_red_origin.svg"
                      alt="Handshake-deal Svgrepo.com"
                    />
                  }
                  fontWeight={700}
                  gap="8px"
                  minW="128px"
                  borderRadius="8px"
                  as={Link}
                  href="/documents/add"
                >
                 Ajout de personnes
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
              gap={3}
            >
              <Box w={{ base: "100%", sm: "20%" }}>
                <AppSelect
                  onChange={(field: string, value: any) => {
                    setCompany(value);
                    mutate(
                      getMembers(page, "", value)
                    );
                  }}
                  value={company}
                  placeholder="client"
                  options={companies}
                  isMulti={false}
                  isLoading={isCompanyLoading}
                  id="company"
                  name="company"
                  handleInputChange={(inputVal) => {
                    mutateCompanies(getCompanies(1, inputVal));
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={members}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={
              members && members?.length > 0 && members[0].num_of_pages
            }
          />
        </Box>
      </Flex>
      <AppViewDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <DrawerHeader>Fiche Personne</DrawerHeader>

        <DrawerBody>
          <VStack alignItems={"flex-start"} gap={"15px"}>
            <VStack alignItems={"center"} gap={"5px"} width={"100%"}>
              <Flex gap="8px" padding={"1px"}>
                <Zoom>
                  <Image
                    src={activeItem?.image}
                    fallbackSrc="/images/dummy.png"
                    alt="logo Image"
                    h="100px"
                    w="100px"
                    fit="contain"
                    borderRadius="16px"
                  />
                </Zoom>
              </Flex>
            </VStack>
            <AppViewListItem label="Client" value={activeItem?.company_name} />
            <AppViewListItem
              label="Description"
              value={activeItem?.description}
            />

            {activeItem?.meta_data &&
              JSON.parse(activeItem?.meta_data)?.map((item: any) => (
                <AppViewListItem
                  key={item.id}
                  label={item.label}
                  value={item.fieldValue as string}
                />
              ))}
          </VStack>
        </DrawerBody>
      </AppViewDrawer>
    </>
  );
}
