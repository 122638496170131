import { CloseIcon, EditIcon, SearchIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import AppViewDrawer from "components/AppViewDrawer";
import AppViewListItem from "components/AppViewListItem";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  name: string;
  actionHeader: undefined;
};

export default function CategoryManagementSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeItem, setActiveItem] = useState(null);
  const navigate = useNavigate();
  const [searchBarValue34, setSearchBarValue34] = useState("");
  const {hasPermission} = useAuthentication()
  const { getCategories } = useApi();
  const pageIndex = 1;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { data: categories, mutate } = useCache("categories", () => getCategories(page, searchBarValue34));
  const handleSearch = (event: any) => {
    const value = event.target.value
    mutate(getCategories(page,value))
  };
  const handleClearSearch = (event: any) => {
    setSearchBarValue34("");
    mutate(getCategories(page,""))
  }
  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(getCategories(pg));
  };
  const handleSetNextPage = () => {
    if (categories.length > 0 && categories[0].num_of_pages >= page + 1) {
      setPage((prev: any) => prev + 1);
      mutate(getCategories(page + 1));
    }
  };
  const handleSetPrevPage = () => {
    if (categories.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(getCategories(page + 1));
    }
  };
  const handleViewItem = useCallback(
    (item: any) => {
      setActiveItem(item);
      onOpen();
    },
    [onOpen, setActiveItem]
  );
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Catégorie</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"} width={"100%"} justifyContent={"flex-end"}>
            {hasPermission("productcategory", "update") && <IconButton
              variant="outline"
              colorScheme="gray_stroke_0"
              aria-label="Edit"
              size={"sm"}
              icon={<EditIcon />}
              borderRadius={"4px"}
              onClick={() => navigate(`edit/${info.getValue()}`)}
            />}
            {hasPermission("productcategory", "get") &&<IconButton
              variant="outline"
              colorScheme="gray_stroke_0"
              aria-label="view"
              size={"sm"}
              icon={<ViewIcon />}
              borderRadius={"4px"}
              onClick={() => handleViewItem(info.row.original)}
            />}
          </HStack>
        ),
        header: (info) => (
          <Flex px="15px" py="14px" justifyContent={"flex-end"}>
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [navigate, handleViewItem, hasPermission]);

  return (
    <>
      {/* dealer management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
        minHeight={"100vh"}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
              Catégorie
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            >
              
             
              {hasPermission("productcategory", "create") &&<Button
                size="xl"
                variant="outline"
                colorScheme="red_origin"
                leftIcon={
                  <Image
                    src="images/img_handshake_deal_svgrepo_com_red_origin.svg"
                    alt="Handshake-deal Svgrepo.com"
                  />
                }
                fontWeight={700}
                gap="8px"
                minW="128px"
                borderRadius="8px"
                as={Link}
                href="/categories/add"
              >
                Créer une Catégorie
              </Button>}
            </Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
            >
              <InputGroup w={{ base: "100%", sm: "30%" }}>
                <InputLeftElement>
                  <Image
                    src="images/img_search_black.svg"
                    alt="Search"
                    w="16px"
                    h="14px"
                  />
                </InputLeftElement>
                <Input
                  placeholder={`Rechercher`}
                  value={searchBarValue34}
                  onChange={(event:any) => setSearchBarValue34(event.currentTarget.value)}
                  gap="8px"
                  borderWidth="1px"
                  borderRadius="8px"
                />
                <InputRightElement>
                  {searchBarValue34?.length > 0 ? (
                    <CloseIcon onClick={handleClearSearch} />
                  ) : null}
                </InputRightElement>
              </InputGroup>
                <IconButton onClick={handleSearch} ml={2} borderRadius={"10px"} py={"19px"} border={"1px solid #e2e4e6"} aria-label="search" icon={<SearchIcon/>} justifyContent={"center"} alignItems={"center"} />
            </Flex>
            <Image
              src="images/img_dot_menu.svg"
              alt="Menu Image"
              h="24px"
              w={{ md: "24px", base: "100%" }}
            />
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={categories}
          />
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={categories && categories?.length > 0 && categories[0].num_of_pages}
          />
        </Box>
      </Flex>
      <AppViewDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <DrawerHeader>Fiche Catégorie</DrawerHeader>

        <DrawerBody>
          <VStack alignItems={"flex-start"} gap={"15px"}>
            <HStack>
              <Button
                size="xl"
                variant="outline"
                colorScheme="green_360"
                leftIcon={<EditIcon />}
                fontWeight={700}
                gap="8px"
                borderRadius="8px"
                as={Link}
                href={`/categories/edit/${activeItem?.id}`}
              >
                Modifier
              </Button>
            </HStack>
            <AppViewListItem label="Nom" value={activeItem?.name} />
          </VStack>
        </DrawerBody>
      </AppViewDrawer>
    </>
  );
}
