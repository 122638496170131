/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Icon, SimpleGrid } from "@chakra-ui/react";
import MiniStatistics from "components/AppCard/Ministatistics";
import IconBox from "components/Icon";
import colors from "theme/foundations/colors";
  // Assets
  // Custom components

  import { useAuthentication } from "hooks/useAuthentication";
import { MdBarChart, MdEditDocument, MdList, MdNumbers, MdQrCode } from "react-icons/md";
import { useNavigate } from "react-router-dom";
  
  export default function MiniStatsSection(props:any) {
    const {data, startDate, endDate} = props;
    const {company_signups, all_products, qr_code_requests, total_scanned, all_members} = data;
    const {user} = useAuthentication()
    const navigate = useNavigate()

    // Chakra Color Mode
    return (
      <Box>
        <SimpleGrid
          columns={{ base: 1, md: 5, lg: 5, "2xl": 6 }}
          gap='10px'>
          <MiniStatistics
          onClick={() => navigate("/qrcode-requests", {state:{startDate, endDate}})}
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={colors.gray[100]}
                icon={
                  <Icon w='25px' h='25px' as={MdBarChart} color={'red_origin'} />
                }
              />
            }
            name='Nombre de demandes de Codes QR'
            value={qr_code_requests}
          />
          {(!user?.user?.company_id && !user?.user?.dealer_id)  && <MiniStatistics
          onClick={() => navigate("/clients", {state:{startDate, endDate}})}
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={colors.gray[100]}
                icon={
                  <Icon w='25px' h='25px' as={MdNumbers} color={'red_origin'} />
                }
              />
            }
            name='Nombre de nouveaux clients'
            value={company_signups}
          />}
          {(!user?.user?.company_id && !user?.user?.dealer_id) && <MiniStatistics
          onClick={() => navigate("/products", {state:{startDate, endDate}})}
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={colors.gray[100]}
                icon={<Icon w='25px' h='25px' as={MdList} color='red_origin' />}
              />
            }
            name='Nombre de nouveaux produits'
            value={all_products}
          />}
          {(!user?.user?.company_id && !user?.user?.dealer_id) && <MiniStatistics
          onClick={() => navigate("/documents", {state:{startDate, endDate}})}
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={colors.gray[100]}
                icon={<Icon w='25px' h='25px' as={MdEditDocument} color='red_origin' />}
              />
            }
            name='Nombre de nouveaux documents'
            value={all_members}
          />}
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={colors.gray[100]}
                icon={
                  <Icon w='25px' h='25px' as={MdQrCode} color={'red_origin'} />
                }
              />
            }
            name='Nombre de scans'
            value={total_scanned}
          />
        </SimpleGrid>
      </Box>
    );
  }
  