import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({ field: { cursor: "text", color: "black", borderColor: "gray_stroke.0" } });

const sizes = {
  md: defineStyle({
    field: {
      fontSize: "12px",
      px: "40px",
      height: "64px",
    },
  }),
  sm: defineStyle({
    field: {
      fontSize: "14px",
      height: "44px",
      px: "12px",
    },
  }),
  xs: defineStyle({
    field: {
      fontSize: "14px",
      height: "40px",
      px: "12px",
    },
  }),
};

const variants = {
  outline: defineStyle((props) => {
    const { colorScheme } = props;
    const colorCombinations = {
      gray_stroke_0: {
        field: {
          borderColor: "gray_stroke.0",
          borderBottomWidth: "1px",
          borderStyle: "solid",
          color: "black",
        },
      },
    };
    return colorCombinations[colorScheme as keyof typeof colorCombinations] || colorCombinations["gray_stroke_0"];
  }),
  fill: defineStyle((props) => {
    const { colorScheme } = props;
    const colorCombinations = {
      white: {
        field: {
          bg: "white",
          color: "black",
        },
      },
    };
    return colorCombinations[colorScheme as keyof typeof colorCombinations] || colorCombinations["white"];
  }),
};

const Input = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: "fill",
    size: "xs",
  },
});
export default Input;
