import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";

type PaginationProps = {
  pageIndex: number;
  setNextPageIndex: any;
  setPrevPageIndex: any;
  pageSize?: any;
  setPageSize?: any;
  jumpToPage: any;
  numberOfPages?:number;
};

export function Pagination({
  pageIndex,
  setNextPageIndex,
  setPrevPageIndex,
  jumpToPage,
  numberOfPages=1,
}: PaginationProps) {
  return (
    <>
      <Flex justifyContent="flex-end" m={4} alignItems="center" gap={1}>
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => jumpToPage(1)}
              // isDisabled={!canPreviousPage}
              aria-label={"First page"}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              onClick={setPrevPageIndex}
              // isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
              aria-label={"Prev page"}
            />
          </Tooltip>
        </Flex>

        <Flex justifyContent="space-between" m={4} alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex}
            </Text>{" "}
            /{" "}
            <Text fontWeight="bold" as="span">
              {numberOfPages}
            </Text>
          </Text>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              onClick={setNextPageIndex}
              // isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
              aria-label={"Next page"}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              onClick={() => jumpToPage(numberOfPages)}
              // isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
              aria-label={"Last page"}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </>
  );
}
