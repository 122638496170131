import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, ChakraProps, Flex, Heading, IconButton, Image } from "@chakra-ui/react";
import { useAuthentication } from "hooks/useAuthentication";
import React from "react";
import { MdDocumentScanner, MdLogout, MdQrCode, MdEditDocument } from "react-icons/md";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { pageRoles } from "utils/roles";

const menuItems = [
  {
    label: "Tableau de bord",
    link: "/dashboard",
    icon: (
      <Image
        src="images/img_dashboard_svgrepo_com.svg"
        alt="Dashboard Icon"
        h="16px"
        w="16px"
      />
    ),
    roles: [...pageRoles.superUser, ...pageRoles.companyAdmin],
    model: "dashboard",
  },
  {
    label: "Clients",
    link: "/clients",
    icon: (
      <Image
        src="images/img_handshake_deal_svgrepo_com.svg"
        alt="Clients Icon"
        h="16px"
        w="16px"
      />
    ),
    roles: pageRoles.superUser,
    model: "company",
  },
  {
    label: "Produits",
    link: "/products",
    icon: (
      <Image
        src="images/img_filter.svg"
        alt="Products Icon"
        h="16px"
        w="16px"
      />
    ),
    roles: pageRoles.superUser,
    model: "product",
  },
  // {
  //   label: "Documents",
  //   link: "/documents",
  //   icon: (
  //     <MdEditDocument/>
  //   ),
  //   roles: pageRoles.superUser,
  //   model: "member",
  // },
  {
    label: "Dealers",
    link: "/dealers",
    icon: (
      <Image src="images/dealer_icon.svg" alt="Dealer Icon" h="16px" w="16px" />
    ),
    roles: pageRoles.superUser,
    model: "dealer",
  },
  {
    label: "Utilisateurs",
    link: "/users",
    icon: (
      <Image src="images/img_lock.svg" alt="Users Icon" h="16px" w="16px" />
    ),
    roles: pageRoles.superUser,
    model: "systemuser",
  },
  {
    label: "Codes QR",
    link: "/qrcode-requests",
    icon: (
      <MdQrCode/>
    ),
    roles: pageRoles.superUser,
    model: "qrcoderequest",
  },
  {
    label: "Scans",
    link: "/logs",
    icon: (
      <MdDocumentScanner/>
    ),
    roles: pageRoles.superUser,
    model: "log",
  },
  {
    label: "Paramètres",
    link: "/settings",
    icon: (
      <Image
        src="images/img_settings_svgrepo_com.svg"
        alt="Settings Icon"
        h="16px"
        w="16px"
      />
    ),
  },
];

interface Props extends ChakraProps {
  className?: string;
}

export default function Sidebar3({ ...props }: Props) {
  const [collapsed, setCollapsed] = React.useState(false);
  const {
    authProvider: { signOut },
    hasPermission,
  } = useAuthentication();

  //use this function to collapse/expand the sidebar
  function collapseSidebar() {
    setCollapsed(!collapsed);
  }

  const handleLogout = async () => {
    await signOut();
  };

  return (
    <Box
      {...props}
      minW={"80px"}
      width="246px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      as={Sidebar}
      pt={{ base: "20px", sm: "24px" }}
      flexDirection="column"
      display="flex"
      bg="white"
      boxShadow="xs"
      h="100vh"
      top="0px"
      overflow="auto"
      px="5px"
      sx={{
        position: "sticky !important",
      }}
    >
      <Box
        position={"absolute"}
        top={0}
        right={5}
        mb={10}
        onClick={collapseSidebar}
      >
        <IconButton
          aria-label="Toggle Menu"
          fontSize="20px"
          icon={collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          borderWidth={"2px"}
          borderRadius={"15px"}
          borderColor={"gray_stroke.0"}
          borderStyle={"solid"}
          width={"30px"}
          height={"30px"}
          size={"sm"}
        />
      </Box>
      <Image
        src="images/logo.png"
        alt="Sidebar Image"
        h={collapsed ? "80px" : "100px"}
        mt="30px"
        w={collapsed ? "80px" : "100px"}
        fit="contain"
        borderRadius="24px"
      />
      <Box
        menuItemStyles={{
          button: {
            padding: "8px",
            backgroundColor: "#ffffff",
            color: "#5f6d7e",
            fontWeight: 400,
            fontSize: "12px",
          },
        }}
        rootStyles={{ "&>ul": { gap: "16px" } }}
        as={Menu}
        mt="10px"
        pr="6px"
        display="flex"
        alignSelf="stretch"
        flexDirection="column"
        w="100%"
      >
        <MenuItem href={menuItems[0].link} icon={menuItems[0].icon} key={menuItems[0].label}>
                {menuItems[0].label}
        </MenuItem>
        {menuItems?.map(
          (menu: any) =>
            // menu?.roles.includes(user?.user?.roles) &&
            hasPermission(menu.model, "get") && menu.model !== "dashboard" && (
              <MenuItem href={menu.link} icon={menu.icon} key={menu.label}>
                {menu.label}
              </MenuItem>
            )
        )}
        {menuItems?.map(
          (menu: any) =>
            // menu?.roles.includes(user?.user?.roles) &&
            menu.model === undefined && (
              <MenuItem href={menu.link} icon={menu.icon} key={menu.label}>
                {menu.label}
              </MenuItem>
            )
        )}
       
      </Box>
      {!collapsed ? (
        <Box mt="192px" alignSelf="stretch">
          <Flex flexDirection="column" alignItems="center">
            <Image
              src="images/img_logout_2_svgrepo_com.svg"
              alt="Logout Icon"
              h="40px"
              zIndex={1}
              w="40px"
              position="relative"
            />
            <Flex
              mt="-24px"
              gap="12px"
              position="relative"
              bg="red_bg"
              alignSelf="stretch"
              flexDirection="column"
              alignItems="center"
              px="18px"
              py={{ base: "20px", sm: "22px" }}
              borderRadius="8px"
            >
              <Heading size="headingsm" as="h6" fontFamily="Arimo">
                Origin360
              </Heading>
              <Button
                size="xl"
                colorScheme="red_origin"
                leftIcon={<MdLogout/>}
                fontFamily="Arimo"
                fontWeight={600}
                fontSize={"8px"}
                gap="4px"
                alignSelf="stretch"
                // mx="22px"
                borderRadius="8px"
                onClick={handleLogout}
              >
                Se déconnecter
              </Button>
            </Flex>
          </Flex>
        </Box>
      ) : null}
    </Box>
  );
}
