export function getDeviceInfoAndIp() {
  const devInfo = { ip: "", deviceId: window.navigator.userAgent };
  fetch("https://api.ipify.org/")
    .then((r) => r.text())
    .then((data) => (devInfo.ip = data))
    .catch((err) => console.log(err));

  return devInfo;
}

export const openLoader = (loadingRef: any) =>
  loadingRef.current && loadingRef.current.open();
export const closeLoader = (loadingRef: any) =>
  loadingRef.current && loadingRef.current.close();

export function stringifyErrors(errors: any) {
  let str_errors = ``;
  for (let key in errors) {
    str_errors = str_errors + `${key}: ${errors[key]}\n`;
  }

  return str_errors;
}


// Toast

type toastType = "success" | "error" | "info" | "warning";

type toastTypesType = {
  success: toastType;
  error: toastType;
  info: toastType;
  warning: toastType;
};

export const toastTypes: toastTypesType = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
};

type ShowToastProps = {
  toast:any; 
  message:string;
  status:toastType
  description?:string
}

export const showToast = ({toast, message, status, description}:ShowToastProps) => {
  toast({
    position: 'top-right',
    status: status,
    title: message,
    description: description
  })
}