import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import AppInput from "components/AppInput";

import AppSelect from "components/AppSelect";
import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageRolePage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const { user } = useAuthentication();
  const { getRole, addRole, updateRole, getPermissions } = useApi();
  const {
    data: role,
    isLoading,
    mutate,
  } = useCache("role", () => getRole(+id || 0));

  const { data: permissions } = useCache("permissions", () => getPermissions());

  const initialValues = useMemo(() => {
    return {
      group: role?.group_id || "",
      alias: role?.alias || "",
      roles: role?.permissions || [],
      submit: null,
    };
  }, [role]);

  const validationSchema = Yup.object().shape({
    group: Yup.string().trim().required("Titre requis"),
    alias: Yup.string().trim().required("Alias requis"),
    roles: Yup.array()
      .of(
        Yup.object({
          value: Yup.string(),
        })
      )
      .min(1, "Rôle requis")
      .required("Rôle requis"),
  });
  const {
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const postData: any = {
          group_id: values.group,
          alias: values.alias,
          permissions: values.roles,
        };
        let response: any = null;
        if (id) {
          response = await updateRole({
            id: id,
            ...postData,
          });
        } else {
          response = await addRole({
            ...postData,
          });
        }
        if (!response.error_code || response.errors) {
          dispatch(
            showToast({
              toast: toast,
              message: id
                ? "Rôle mis à jour avec succès"
                : "Rôle créé avec succès",
              status: toastTypes.success,
            })
          );
          if (id) {
            mutate(getRole(+id));
            navigate("/roles");
          } else {
            navigate(`/roles`);
          }
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
        }
      } catch (ex) {
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
      }
    },
  });
  return (
    <>
      {!isLoading && (
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
          minHeight={"100vh"}
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                  {id ? "Modifier le rôle" : "Créer un rôle"}
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                ></Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="16px" flexDirection="column">
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Rôle</Text>
                    <Flex
                      gap="4px"
                      flexDirection="column"
                      alignItems="start"
                      width={"100%"}
                    >
                      <AppSelect
                        id="group"
                        name="group"
                        onChange={(field: string, value: any) => {
                          setFieldValue(field, value);
                        }}
                        value={values.group || initialValues.group}
                        placeholder="Veuillez sélectionner un group"
                        options={user?.user.groups}
                        isMulti={false}
                        getOptionLabel={(option: any) => option.alias}
                      />
                    </Flex>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Alias</Text>
                    <FormControl
                      isInvalid={!!errors.alias && (touched.alias as boolean)}
                    >
                      <AppInput
                        required
                        placeholder={`Alias`}
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="firstName"
                        name="alias"
                        autoComplete="alias"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.alias || initialValues.alias}
                        showErrorMessage={Boolean(
                          touched.alias && errors.alias
                        )}
                        errorMessage={errors.alias}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Permissions</Text>
                    <Flex
                      gap="4px"
                      flexDirection="column"
                      alignItems="start"
                      width={"100%"}
                    >
                      <AppSelect
                        id="roles"
                        name="roles"
                        onChange={(field: string, value: string) => {
                          setFieldValue(field, value);
                        }}
                        value={values.roles || initialValues.roles}
                        placeholder="Veuillez sélectionner un rôle"
                        options={permissions}
                        isMulti={true}
                        isLoading={isLoading}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex mt="24px" gap="12px">
                <Button
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                  onClick={() => window.history.back()}
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}
