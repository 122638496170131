const colors = {
  amber: {
    a400: "#ffc300",
  },
  black: "#000000",
  color_black: {
    "900": "#0a0a0a",
    "900_0a": "#0000000a",
    "900_0f": "#0000000f",
    "900_14": "#00000014",
    "900_19": "#00000019",
  },
  blue: "#0075ff",
  blue_bg: "#d0e5ff",
  deep_orange: {
    a200: "#ff6c2c",
  },
  gray: {
    "100": "#f1f3f4",
    "300": "#dee1e6",
    "400": "#babcbe",
    "700": "#5f6368",
    "800": "#3c4043",
    "900": "#202124",
    "100_01": "#f6f7f9",
    "100_02": "#f5f5f5",
    "700_01": "#696a6c",
    "800_01": "#494c4f",
    "900_0a": "#1018280a",
  },
  gray_dark: "#5d6465",
  gray_description: "#5f6d7e",
  gray_stroke: {
    "0": "#dfe4ea",
    "1": "#dfe4ea87",
    "2": "#dae0e6",
    "3": "#5f6d7e99",
  },
  grayish_tabs: "#a7a9b7",
  green_360: "#02ad02",
  green_360_ctg: "#edfded",
  green: {
    "600": "#3eaf3f",
    "600_01": "#239f57",
    a700: "#27ca40",
  },
  neutral: {
    "600": "#eaebf0",
  },
  pink: {
    "900": "#6b0d12",
  },
  red: {
    "600": "#e14942",
    "900": "#8c181a",
    a200: "#ff6058",
  },
  red_bg: "#fff3f2",
  red_origin: "#e33621",
  very_light_gray: "#f1f4f8",
  violet_bg: "#f5f3ff",
  violet_ctg: "#8b5cf6",
  white: "#ffffff",
  color_white: {
    a700_99: "#ffffff99",
    a700_dd: "#ffffffdd",
  },
  yellow: "#eba111",
  color_yellow: {
    "700": "#ffc130",
    "800": "#e1a325",
  },
  yellow_bg: "#fcefd6",
};
export default colors;
