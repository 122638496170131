import { Flex, Heading, IconButton, Image, Text } from "@chakra-ui/react";
import { useAuthentication } from "hooks/useAuthentication";
import { MdLogout } from "react-icons/md";

export default function WelcomeSection() {
  const {
    user,
    authProvider: { signOut },
  } = useAuthentication();
  const handleLogout = async () => {
    await signOut();
  };
  return (
    <>
      {/* welcome section */}
      <Flex
        justifyContent="space-between"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
        // flexDirection={{ md: "row", base: "column" }}
        maxWidth={"100%"}
      >
        <Flex
          gap="8px"
          flex={1}
          alignItems="center"
          alignSelf={{ md: "auto", base: "stretch" }}
        >
          <Image
            src={user?.user?.profile_picture || "images/img_ellipse_213.png"}
            alt="Profile Image"
            h="56px"
            w="56px"
            fit="contain"
            borderRadius="28px"
          />
          <Flex gap="2px" flex={1} flexDirection="column" alignItems="start">
            <Text color="gray_description">Bienvenue</Text>
            <Heading size="button_text_bold_14" as="h1">
              {user?.user?.name}
            </Heading>
          </Flex>
        </Flex>
        <IconButton
          size="3xl"
          colorScheme="red_origin"
          icon={<MdLogout />}
          aria-label="281:4184-Mute Button"
          w="52px"
          borderRadius="14px"
          onClick={handleLogout}
        />
      </Flex>
    </>
  );
}
