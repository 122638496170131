import { Box, Flex, Heading, useToast } from "@chakra-ui/react";
import AppDatePicker from "components/AppDatePicker";
import Sidebar3 from "components/Sidebar3";
import WelcomeSection from "components/WelcomeSection";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Store } from "store";
import { closeLoader, openLoader, showToast, toastTypes } from "utils";
import BarChartSection from "./BarChartSection";
import MiniStatsSection from "./MiniStatsSection";
import QRCodeSection from "./QRCodeSection";

export default function DashboardPage() {
  const loadingRef = useRef<any>(null);
  const { dispatch } = useContext(Store);
  const toast = useToast();
  const { getReport, generateReportCSV } = useApi();
  const [selectedDates, setSelectedDates] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  const {
    data: report,
    mutate,
    isLoading,
  } = useCache("getReports", () =>
    getReport({
      startDate: selectedDates[0]
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
      endDate: selectedDates[1]
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
    })
  );
  const handleGenerateReportCSV = async () => {
    openLoader(loadingRef);
    const response = await generateReportCSV({
      startDate: selectedDates[0]
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
      endDate: selectedDates[1]
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
    });
    if (response) {
      dispatch(
        showToast({
          toast: toast,
          message: "Un email avec le lien de téléchargement vous sera envoyé.",
          status: toastTypes.success,
        })
      );
    } else {
      dispatch(
        showToast({
          toast: toast,
          message: "Impossible de générer le rapport.",
          status: toastTypes.error,
        })
      );
    }
    closeLoader(loadingRef);
  };
  return (
    <>
      <Helmet>
        <title>Codes QR Statistics - Origin360 Tracking and Reporting</title>
        <meta
          name="description"
          content="Monitor Code QR generation, reports, and product scans with Origin360. Get insights into counterfeit alerts and order trends for effective product tracking."
        />
      </Helmet>
      <Box bg="white" w="100%">
        <Box>
          <Flex>
            <Sidebar3 />
            <Flex
              gap="10px"
              bg="very_light_gray"
              flex={1}
              flexDirection="column"
              py="20px"
            >
              {/* welcome section */}
              <WelcomeSection />
              {!isLoading && (
                <Flex
                  gap="20px"
                  flexDirection="column"
                  alignItems="start"
                  mx={{ md: "22px", base: "0px" }}
                >
                  <Heading size="headinglg" fontFamily="Arimo">
                    Tableau de bord{" "}
                  </Heading>
                  <Flex w="100%" flexDirection="column">
                    {/* q r code section */}
                    <QRCodeSection
                      handleGenerateReportCSV={handleGenerateReportCSV}
                    />
                  </Flex>
                  <Flex
                    w="100%"
                    flexDirection="row"
                    justifyContent={"flex-end"}
                  >
                    <AppDatePicker
                      selectedDates={selectedDates}
                      onDateChange={(date) => {
                        console.log(date);
                        setSelectedDates(date);
                        mutate(
                          getReport({
                            startDate: date[0]
                              ?.toISOString()
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("-"),
                            endDate: date[1]
                              ?.toISOString()
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("-"),
                          })
                        );
                      }}
                    />
                  </Flex>
                  <Flex w="100%" flexDirection="column">
                    {/* q r code section */}
                    <MiniStatsSection
                      data={report || {}}
                      startDate={selectedDates[0]
                        .toISOString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("-")}
                      endDate={selectedDates[1]
                        ?.toISOString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("-")}
                    />
                  </Flex>
                  <Flex w="100%" flexDirection="column">
                    {/* q r code section */}
                    <BarChartSection
                      data={report?.monthly_request_report || []}
                      title="Génération de Codes QR au fil du temps"
                    />
                  </Flex>
                  <Flex w="100%" flexDirection="column">
                    {/* product code section */}
                    <BarChartSection
                      data={report?.monthly_product_report || []}
                      title="Nombre de nouveaux produits au fil du temps"
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
