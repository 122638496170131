import { Button, Container, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
 const   navigate = useNavigate()
  return (
    <>
      <Container>
        <Stack
          flexDirection={"column"}
          width={"100%"}
          height={"80vh"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={5}
        >
          <Text color={"red_origin"} sx={{ fontSize: 150, fontWeight: "bold" }}>
            Oops!
          </Text>
          <Text sx={{ fontSize: 20, textAlign: "center" }}>
            Quelque chose s'est mal passée, veuillez essayer ce qui suit :
          </Text>
          <div>
            <ul>
              <li>Vérifiez votre connection internet</li>
              <li>Rechargez la page</li>
              <li>
                Si le problème persiste, contactez l'administrateur du système.
              </li>
            </ul>
          </div>
          <Button
            size="4xl"
            colorScheme="red_origin"
            fontFamily="Arimo"
            fontWeight={700}
            minW="132px"
            borderRadius="8px"
            onClick={() => navigate("/")}
          >
            Go Home
          </Button>
        </Stack>
      </Container>
    </>
  );
}
