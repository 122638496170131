import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Image,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { PhoneNumberInput } from "components/PhoneNumberInput";

import AppInput from "components/AppInput";
import AppSelect from "components/AppSelect";
import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageClientPage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const [logoSrc, setLogoSrc] = useState(null);
  const [bannerSrc, setBannerSrc] = useState(null);
  const [repImageSrc, setRepImageSrc] = useState(null);
  const {
    getCompany,
    addCompany,
    updateCompany,
    getCities,
    getDealers,
    uploadCompanyBanner,
    uploadCompanyLogo,
    uploadCompanyRepPhoto,
    countries,
    isCountryLoading,
  } = useApi();
  const {
    data: company,
    isLoading,
    mutate,
  } = useCache("company", () => getCompany(+id || 0));
  const { data: dealers } = useCache("dealers", () => getDealers());

  const handleFileChange = async (event, imageType) => {
    const file = event.target.files[0];
    let rsp = undefined;
    let formData: any = new FormData();
    formData.append("images", file, file.name);
    if (file) {
      const url = URL.createObjectURL(file);
      if (imageType === "logo" && id) {
        setLogoSrc(url);
        rsp = await uploadCompanyLogo(formData, +id);
      }
      if (imageType === "banner" && id) {
        setBannerSrc(url);
        rsp = await uploadCompanyBanner(formData, +id);
      }
      if (imageType === "repImage" && id) {
        setRepImageSrc(url);
        rsp = await uploadCompanyRepPhoto(formData, +id);
      }
    }
    if (rsp?.image_id) {
      dispatch(
        showToast({
          toast: toast,
          message: "Image téléchargée avec succès",
          status: toastTypes.success,
        })
      );
      mutate(getCompany(+id));
      // navigate("/clients");
    }
  };

  const initialValues = useMemo(() => {
    return {
      email: company?.email || "",
      emailConfirm: company?.email || "",
      dealer: company?.dealer_id || "",
      city: company?.address?.city_id || "",
      country: company?.country_id || "",
      cityCountry: company?.address?.country_id || "",
      addressDescription: company?.address?.description || "",
      phoneNumber: company?.phone_number || "",
      // minQRCodeNumber: company?.min_qr_code_number || "",
      name: company?.name || "",
      repName: company?.rep_name || "",
      submit: null,
    };
  }, [company]);
  // const initialValues = {
  //   email: company?.email || "",
  //   dealer: company?.dealer_id || "",
  //   area: company?.address?.area_id || "",
  //   city: company?.address?.city_id || "",
  //   country: company?.country_id || "",
  //   addressDescription: company?.address?.description || "",
  //   phoneNumber: company?.phone_number || "",
  //   // minQRCodeNumber: company?.min_qr_code_number || "",
  //   name: company?.name || "",
  //   repName: company?.rep_name || "",
  //   submit: null,
  // }
  const validationSchema = Yup.object().shape({
    // minQRCodeNumber: Yup.string().required(
    //   "un nombre minimum de qrcode est requis"
    // ),
    name: Yup.string().trim().required("Nom du client requis"),
    repName: Yup.string()
      .trim()
      .required("Nom et prenoms sont requis")
      .test("is-two-names", "Nom et prenoms sont requis", (value) => {
        return (
          value &&
          value.split(" ").length >= 2 &&
          value.split(" ").every((part) => part.trim() !== "")
        );
      }),
    email: Yup.string().email().trim().required("email requis"),
    emailConfirm: Yup.string()
      .oneOf(
        [Yup.ref("email"), ""],
        "La email et la email de confirmation doivent être les mêmes"
      )
      .required("Confirmer l'email"),
    phoneNumber: Yup.string()
      .trim()
      .required("le numéro de téléphone est requis"),
    city: Yup.string().trim().required("ville requis"),
    country: Yup.string().trim().required("pays requis"),
    cityCountry: Yup.string().trim().required("pays requis"),
    addressDescription: Yup.string().trim().required("l'adresse est requise"),
    dealer: Yup.number().required("un revendeur est requis"),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        // const [repFirstName, repLastName] = values.repName.split(" ");
        // const otherNames = repLastName.slice(1).join(' ');
        const names = values.repName.split(" ");
        const repLastName = names[0]; // Get first name
        const repFirstName = names.slice(1).join(" "); // Get other names as a string
        const postData: any = {
          name: values.name,
          rep_first_name: repFirstName,
          rep_last_name: repLastName,
          phone_number: values.phoneNumber,
          email: values.email,
          dealer_id: values.dealer,
          // min_qr_code_number: values.minQRCodeNumber,
          country_id: values.country,
          address: {
            city_id: values.city,
            description: values.addressDescription,
          },
        };
        let response: any = null;
        if (id) {
          response = await updateCompany({
            id: id,
            ...postData,
          });
        } else {
          response = await addCompany({
            ...postData,
          });
        }
        if (response.id) {
          dispatch(
            showToast({
              toast: toast,
              message: id
                ? "Client mis à jour avec succès"
                : "Client créé avec succès",
              status: toastTypes.success,
            })
          );
          if (id) {
            mutate(getCompany(+id));
            navigate("/clients");
          } else {
            navigate(`/clients/edit/${response?.id}`);
          }
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
          setFieldValue("password", "");
        }
      } catch (ex) {
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
        setFieldValue("password", "");
      }
    },
  });
  const {
    data: cities,
    mutate: mutateCity,
    isLoading: isCityLoading,
  } = useCache("cities", () =>
    getCities(values.cityCountry || company?.address?.country_id, 0)
  );
  useEffect(() => {
    if (company) mutateCity(getCities(company?.address?.country_id, 0));
  }, [company, mutateCity, getCities]);
  return (
    <>
      {!isLoading && (
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                  {id ? "Modifier un Client" : ""}
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                ></Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="16px" flexDirection="column">
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <HStack>
                      <Text color="gray_description">Nom du client</Text>
                      <span style={{ color: "red" }}>*</span>
                    </HStack>
                    <FormControl
                      isInvalid={!!errors.name && (touched.name as boolean)}
                    >
                      <AppInput
                        required
                        placeholder={`SOCOCE 2 Plateaux`}
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="name"
                        name="name"
                        autoComplete="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name || initialValues.name}
                        showErrorMessage={Boolean(touched.name && errors.name)}
                        errorMessage={errors.name}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <HStack>
                      <Text color="gray_description">
                        Nom, Prénoms du représentant
                      </Text>
                      <span style={{ color: "red" }}>*</span>
                    </HStack>
                    <FormControl
                      isInvalid={
                        !!errors.repName && (touched.repName as boolean)
                      }
                    >
                      <AppInput
                        required
                        placeholder={`Ronald KOFFI KRA`}
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="repName"
                        name="repName"
                        autoComplete="repName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.repName || initialValues.repName}
                        showErrorMessage={Boolean(
                          touched.repName && errors.repName
                        )}
                        errorMessage={errors.repName}
                      />
                    </FormControl>
                  </Flex>
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <HStack>
                    <Text color="gray_description">
                      Numéro de téléphone du représentant
                    </Text>
                    <span style={{ color: "red" }}>*</span>
                  </HStack>
                  <PhoneNumberInput
                    name="phoneNumber"
                    onChange={setFieldValue}
                    value={values.phoneNumber || initialValues.phoneNumber}
                    countryDialCode={company?.country_dial_code}
                    onBlur={handleBlur}
                    showErrorMessage={Boolean(
                      touched.phoneNumber && errors.phoneNumber
                    )}
                    errorMessage={errors.phoneNumber}
                  />
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <HStack>
                    <Text color="gray_description">Email</Text>
                    <span style={{ color: "red" }}>*</span>
                  </HStack>
                  <FormControl
                    isInvalid={!!errors.email && (touched.email as boolean)}
                  >
                    <AppInput
                      required
                      placeholder={`ronald.koffikra@gmail.com`}
                      type="email"
                      borderWidth="1px"
                      alignSelf="stretch"
                      borderRadius="8px"
                      id="email"
                      name="email"
                      autoComplete="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email || initialValues.email}
                      showErrorMessage={Boolean(touched.email && errors.email)}
                      errorMessage={errors.email}
                    />
                  </FormControl>
                </Flex>

                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <HStack>
                    <Text color="gray_description">
                      Confirmation de l'email
                    </Text>
                    <span style={{ color: "red" }}>*</span>
                  </HStack>
                  <FormControl
                    isInvalid={
                      !!errors.emailConfirm && (touched.emailConfirm as boolean)
                    }
                  >
                    <AppInput
                      required
                      placeholder={`ronald.koffikra@gmail.com`}
                      type="email"
                      borderWidth="1px"
                      alignSelf="stretch"
                      borderRadius="8px"
                      id="emailConfirm"
                      name="emailConfirm"
                      autoComplete="emailConfirm"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.emailConfirm || initialValues.emailConfirm}
                      showErrorMessage={Boolean(
                        touched.emailConfirm && errors.emailConfirm
                      )}
                      errorMessage={errors.emailConfirm}
                    />
                  </FormControl>
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <HStack>
                    <Text color="gray_description">Pays</Text>
                    <span style={{ color: "red" }}>*</span>
                  </HStack>
                  <Flex
                    gap="4px"
                    flexDirection="column"
                    alignItems="start"
                    width={"100%"}
                  >
                    <FormControl
                      isInvalid={
                        !!errors.cityCountry && (touched.cityCountry as boolean)
                      }
                    ></FormControl>

                    {/* <AppDropdownSelect
                      name="cityCountry"
                      data={countries}
                      value={values.cityCountry || initialValues.cityCountry}
                      onChange={(field: string, value: any) => {
                        setFieldValue(field, value)
                        mutateCity(getCities(+values.cityCountry))
                      }}
                      placeholder="Sélectionnez un pays"
                    /> */}
                    <AppSelect
                      id="cityCountry"
                      name="cityCountry"
                      onChange={(field: string, value: any) => {
                        setFieldValue(field, value);
                        mutateCity(getCities(+values.cityCountry));
                      }}
                      value={values.cityCountry || initialValues.cityCountry}
                      placeholder="Veuillez sélectionner un pays"
                      options={countries}
                      isMulti={false}
                      isLoading={isCountryLoading}
                      showErrorMessage={Boolean(
                         errors.cityCountry
                      )}
                      errorMessage={errors.cityCountry}
                      onBlur={handleBlur}
                    />
                  </Flex>
                </Flex>
                {values.country && cities?.length > 0 && (
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <HStack>
                      <Text color="gray_description">Ville</Text>
                      <span style={{ color: "red" }}>*</span>
                    </HStack>
                    <Flex
                      gap="4px"
                      flexDirection="column"
                      alignItems="start"
                      width={"100%"}
                    >
                      <FormControl
                        isInvalid={!!errors.city && (touched.city as boolean)}
                      ></FormControl>
                      {/* <AppDropdownSelect
                      readonly={values.cityCountry !== "" || initialValues.cityCountry !== "" ? false : true}
                      name="city"
                      data={cities}
                      value={values.city || initialValues.city}
                      onChange={setFieldValue}
                      placeholder="Sélectionnez une ville"
                    /> */}
                      <AppSelect
                        id="city"
                        name="city"
                        onChange={setFieldValue}
                        value={values.city || initialValues.city}
                        placeholder="Veuillez sélectionner une ville"
                        options={cities}
                        isMulti={false}
                        isLoading={isCityLoading}
                        showErrorMessage={Boolean(
                          touched.city && errors.city
                        )}
                        errorMessage={errors.city}
                        onBlur={handleBlur}
                      />
                    </Flex>
                  </Flex>
                )}
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <HStack>
                    <Text color="gray_description">Adresse</Text>
                    <span style={{ color: "red" }}>*</span>
                  </HStack>
                  <FormControl
                    isInvalid={
                      !!errors.addressDescription &&
                      (touched.addressDescription as boolean)
                    }
                  >
                    <Textarea
                      required
                      borderWidth="1px"
                      alignSelf="stretch"
                      borderRadius="8px"
                      id="addressDescription"
                      name="addressDescription"
                      autoComplete="addressDescription"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={
                        values.addressDescription ||
                        initialValues.addressDescription
                      }
                      // showErrorMessage={Boolean(touched.addressDescription && errors.addressDescription)}
                      // errorMessage={errors.addressDescription}
                    />
                    <FormErrorMessage style={{ color: "red" }}>
                      {errors.addressDescription}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <HStack>
                    <Text color="gray_description">Dealer</Text>
                    <span style={{ color: "red" }}>*</span>
                  </HStack>
                  <Flex
                    gap="4px"
                    flexDirection="column"
                    alignItems="start"
                    width={"100%"}
                  >
                    {/* <AppDropdownSelect
                      name="dealer"
                      data={dealers}
                      value={values.dealer || initialValues.dealer}
                      onChange={setFieldValue}
                      placeholder="Sélectionnez une dealer"
                    /> */}
                    <AppSelect
                      id="dealer"
                      name="dealer"
                      onChange={(field: string, value: any) => {
                        setFieldValue(field, value);
                      }}
                      value={values.dealer || initialValues.dealer}
                      placeholder="Veuillez sélectionner un dealer"
                      options={dealers}
                      isMulti={false}
                      isLoading={isCityLoading}
                    />
                  </Flex>
                </Flex>

                {id && (
                  <>
                    <Flex gap="8px" flexDirection="column" alignItems="start">
                      <HStack>
                        <Text color="gray_description" mt="16px">
                          Bannière
                        </Text>
                      </HStack>
                      <AppInput
                        type="file"
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        name="banner"
                        id="banner"
                        onChange={(e: any) => handleFileChange(e, "banner")}
                        display="none"
                      />
                      <Flex
                        gap="16px"
                        alignSelf="stretch"
                        alignItems="start"
                        flexDirection={{ md: "row", base: "column" }}
                      >
                        <Image
                          src={
                            bannerSrc || company?.banner
                              ? bannerSrc || company?.banner
                              : "images/image_upload_preview.jpeg"
                          }
                          alt="Banner Image"
                          h="96px"
                          alignSelf="center"
                          w={{ md: "50%", base: "100%" }}
                          fit="contain"
                          borderRadius="12px"
                          onClick={() =>
                            document.getElementById("banner").click()
                          }
                        />
                        {/* <a href="/">
                          <Image
                            src="images/img_delete_svgrepo_com.svg"
                            borderRadius="50%"
                            alt="Delete Circleimage"
                            h="36px"
                            w="36px"
                          />
                        </a> */}
                      </Flex>
                    </Flex>
                    <Flex gap="8px" flexDirection="column" alignItems="start">
                      <HStack>
                        <Text color="gray_description">Logo du client</Text>
                      </HStack>
                      <AppInput
                        type="file"
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        name="logo"
                        id="logo"
                        onChange={(e: any) => handleFileChange(e, "logo")}
                        display="none"
                      />
                      <Flex
                        gap="16px"
                        alignSelf="flex-start"
                        alignItems="start"
                      >
                        <Image
                          src={
                            logoSrc || company?.logo
                              ? logoSrc || company?.logo
                              : "images/image_upload_preview.jpg"
                          }
                          alt="Client Logo Image"
                          h="100px"
                          alignSelf="center"
                          w="100px"
                          fit="contain"
                          borderRadius="16px"
                          onClick={() =>
                            document.getElementById("logo").click()
                          }
                        />
                        {/* <Image
                          src="images/img_delete_svgrepo_com.svg"
                          borderRadius="50%"
                          alt="Delete Circleimage"
                          h="36px"
                          w="36px"
                        /> */}
                      </Flex>
                    </Flex>
                    <Flex gap="8px" flexDirection="column" alignItems="start">
                      <HStack>
                        <Text color="gray_description">
                          Photo du représentant
                        </Text>
                      </HStack>
                      <AppInput
                        type="file"
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        name="rep_image"
                        id="rep_image"
                        onChange={(e: any) => handleFileChange(e, "repImage")}
                        display="none"
                      />
                      <Flex
                        gap="16px"
                        alignSelf="flex-start"
                        alignItems="start"
                      >
                        <Image
                          src={
                            repImageSrc || company?.rep_image
                              ? repImageSrc || company?.rep_image
                              : "images/image_upload_preview.jpg"
                          }
                          alt="Representative Image"
                          h="100px"
                          alignSelf="center"
                          w="100px"
                          fit="contain"
                          borderRadius="16px"
                          onClick={() =>
                            document.getElementById("rep_image").click()
                          }
                        />
                        {/* <Image
                          src="images/img_delete_svgrepo_com.svg"
                          borderRadius="50%"
                          alt="Delete Circleimage"
                          h="36px"
                          w="36px"
                        /> */}
                      </Flex>
                    </Flex>
                  </>
                )}
              </Flex>
              <Flex mt="24px" gap="12px">
                <Button
                  as="a"
                  href="/clients"
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}

// import React, { useState } from 'react';

// function DynamicForm() {
//   const [fields, setFields] = useState([{ name: '' }]);

//   const handleAddField = () => {
//     setFields([...fields, { name: '' }]);
//   };

//   const handleRemoveField = (index) => {
//     const newFields = fields.filter((_, i) => i !== index);
//     setFields(newFields);
//   };

//   return (
//     <div>
//       {fields.map((field, index) => (
//         <div key={index}>
//           <input
//             type="text"
//             value={field.name}
//             onChange={(e) => {
//               const newFields = [...fields];
//               newFields[index].name = e.target.value;
//               setFields(newFields);
//             }}
//           />
//           <button onClick={() => handleRemoveField(index)}>Remove</button>
//         </div>
//       ))}
//       <button onClick={handleAddField}>Add Field</button>
//     </div>
//   );
// }

// export default DynamicForm;
