import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import AppAsyncSelect from "components/AppAsyncSelect";

import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageMemberQrCodePage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const [memberKeyword, setMemberKeyword] = useState("");
  // const [clientKeyword, setClientKeyword] = useState("");
  const {
    // getCompanies,
    getMembers,
    getQrCodeRequest,
    addQrCodeRequest,
    updateQrCodeRequest,
  } = useApi();
  // const {
  //   data: companies,
  //   mutate: mutateCompanies,
  //   isLoading: isCompanyLoading,
  // } = useCache("companies", () => getCompanies(1));
  const {
    data: members,
    mutate: mutateMembers,
    isLoading: isMemberLoading,
  } = useCache("members", () => getMembers(1, memberKeyword));
  const {
    data: request,
    isLoading,
    mutate,
  } = useCache("getQrCodeRequest", () => getQrCodeRequest(+id || 0));
  const [metaField, setMetaField] = useState<any[]>([]);

  const handleAddMetaFields = () => {
    setMetaField([
      ...metaField,
      {
        id: metaField?.length + 1,
        member: null,
        typeOfRequest: "document",
        client: null,
        numberOfQrCodes: 0,
      },
    ]);
  };
  const handleRemoveMetaFields = (id: number) => {
    const newFields = metaField.filter((_, i) => i !== id - 1);
    setMetaField(newFields);
  };

  const handleMetaFieldChange = (
    id: number,
    name: "member" | "client" | "numberOfQrCodes",
    value: string | number
  ) => {
    const field = metaField[id - 1];
    if (name === "member") {
      field.member = value;
    } else if (name === "client") {
      field.client = value;
    } else {
      field.numberOfQrCodes = +value;
    }
  };

  const initialValues = useMemo(() => {
    return {
      member: request?.member_id || "",
      client: request?.company_id || "",
      submit: null,
    };
  }, [request]);
  const validationSchema = Yup.object().shape({
    member: Yup.string().required("membre requis"),
    client: Yup.string().trim().notRequired(),
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const postData: any = {
          member_id: values.member,
          company_id: values.client,
          type_of_request: "document",
          number_of_qr_codes: 0,
          extra_data: metaField,
          // meta_data: JSON.stringify(metaField)
        };
        let response: any = null;
        if (id) {
          response = await updateQrCodeRequest({
            id: id,
            ...postData,
          });
        } else {
          response = await addQrCodeRequest({
            ...postData,
          });
        }
        if (!response.error_code || response.errors) {
          if (response.id) {
            dispatch(
              showToast({
                toast: toast,
                message: id
                  ? "qrcode request mis à jour avec succès"
                  : "Commande de Code QR traitée avec succès",
                status: toastTypes.success,
              })
            );
            if (id) {
              mutate(getQrCodeRequest(+id));
              navigate("/qrcode-requests");
            } else {
              navigate("/qrcode-requests");
            }
          }
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
        }
      } catch (ex) {
        console.log(ex);
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
      }
    },
  });
  console.log(members)
  return (
    <>
      {!isLoading && (
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
          minH={"100vh"}
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                  {id
                    ? "Modifier qr code request"
                    : "Créer une commande de Codes QR"}
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                ></Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Text color="gray_description">Document</Text>
                  <Flex
                    gap="4px"
                    flexDirection="column"
                    alignItems="start"
                    width={"100%"}
                  >
                    <FormControl
                      isInvalid={
                        !!errors.member && (touched.member as boolean)
                      }
                    ></FormControl>
                    <AppAsyncSelect
                      id="member"
                      name="member"
                      options={members}
                      value={values.member || initialValues.member}
                      onChange={setFieldValue}
                      handleInputChange={(inputVal) => {
                        setMemberKeyword(inputVal);
                        return mutateMembers(getMembers(1, inputVal));
                      }}
                      placeholder="Veuillez sélectionner un document"
                      isLoading={isMemberLoading}
                    />
                  </Flex>
                </Flex>

                {metaField?.length > 0 &&
                  metaField.map((field: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        borderTop: "1px dotted gray",
                        paddingTop: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Flex
                        gap="4px"
                        flexDirection="column"
                        alignItems="start"
                        mb={"20px"}
                      >
                        <Flex
                          w={"100%"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Flex justifyContent={"center"} alignItems={"center"}>
                            <Text color="gray_description">membre</Text>
                          </Flex>
                          <IconButton
                            size="sm"
                            variant="outline"
                            colorScheme="red_origin"
                            icon={
                              <Image
                                src="images/img_delete2svgrepocom.svg"
                                alt="Delete-2 Svgrepo.com"
                              />
                            }
                            fontWeight={700}
                            borderRadius="8px"
                            aria-label="Delete"
                            height={"20px"}
                            mt={"5px"}
                            onClick={() => handleRemoveMetaFields(field.id)}
                          >
                            Supprimer
                          </IconButton>
                        </Flex>
                        <FormControl
                          isInvalid={
                            !!errors.member && (touched.member as boolean)
                          }
                        ></FormControl>
                        <AppAsyncSelect
                          id={"member" + index}
                          name={"member" + index}
                          options={members}
                          value={values.member || initialValues.member}
                          onChange={(fieldName: string, value: any) => {
                            setFieldValue(fieldName, value);
                            handleMetaFieldChange(field.id, "member", value);
                          }}
                          handleInputChange={(inputVal) => {
                            setMemberKeyword(inputVal);

                            return mutateMembers(getMembers(1, inputVal));
                          }}
                          placeholder="Veuillez sélectionner un document"
                          isLoading={isMemberLoading}
                        />
                      </Flex>
                    </div>
                  ))}
                <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Button
                    leftIcon={<AddIcon />}
                    variant="solid"
                    size="4xl"
                    color="gray_description"
                    fontFamily="Arimo"
                    fontWeight={700}
                    borderRadius="8px"
                    width={"100%"}
                    onClick={handleAddMetaFields}
                  >
                    Ajouter un champ
                  </Button>
                </Flex>
              </Flex>
              <Flex mt="24px" gap="12px">
                <Button
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                  onClick={() => window.history.back()}
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}
