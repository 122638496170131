// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-display: swap;
  font-style: normal;
  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
  font-family: "Arimo";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
  font-family: "Arimo";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
  font-family: "Arimo";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
  font-family: "Arimo";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
  font-family: "Arimo";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
  font-family: "Arimo";
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uGAAuG;EACvG,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uGAAuG;EACvG,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uGAAuG;EACvG,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uGAAuG;EACvG,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uGAAuG;EACvG,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uGAAuG;EACvG,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');\n  font-family: \"Arimo\";\n  font-weight: 400;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');\n  font-family: \"Arimo\";\n  font-weight: 400;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');\n  font-family: \"Arimo\";\n  font-weight: 500;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');\n  font-family: \"Arimo\";\n  font-weight: 600;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');\n  font-family: \"Arimo\";\n  font-weight: 700;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');\n  font-family: \"Arimo\";\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
