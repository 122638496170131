const baseStyle = {
  color: "black",
  fontFamily: "Arimo",
};
const sizes = {
  text_regular_12: {
    fontSize: "12px",
    fontWeight: 400,
    fontStyle: "bold",
  },
  text_regular_14: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "bold",
  },
  texts: {
    fontSize: "12px",
    fontWeight: 400,
    fontStyle: "bold",
  },
  textmd: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
  },
  textxl: {
    fontSize: "18px",
    fontWeight: 400,
    fontStyle: "bold",
  },
  text3xl: {
    fontSize: {
      md: "40px",
      base: "36px",
      sm: "38px",
    },
    fontWeight: 400,
    fontStyle: "bold",
  },
};
const defaultProps = {
  size: "text_regular_12",
};

const Text = {
  baseStyle,
  sizes,
  defaultProps,
};
export default Text;
