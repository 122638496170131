import { useAuthentication } from "hooks/useAuthentication";
import useAutoLogout from "hooks/useAutoLogOut";
import Routes from "./routes";

function App() {
  const {authProvider:{signOut}} = useAuthentication()
  useAutoLogout(signOut, 1500000);
  return (
      <>
        <Routes />
      </>
  );
}

export default App;
