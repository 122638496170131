import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const sizes = {
  xs: defineStyle({
    control: {},
    label: {},
  }),
  sm: defineStyle({
    control: {
      h: "16px",
      w: "16px",
      borderRadius: "3px",
    },
    label: {},
  }),
  md: defineStyle({
    control: {
      h: "20px",
      w: "20px",
      borderRadius: "5px",
    },
    label: {},
  }),
};

const variants = {
  primary: defineStyle({
    control: {
      boxShadow: "lg",
      _checked: {
        borderColor: "gray_description",
        borderWidth: "1px",
        borderStyle: "solid",
        bg: "red_origin",
        "&:hover": {
          // bg: "white",
          borderWidth: "1px",
          borderColor: "gray_description",
        },
      },
    },
  }),
};

const Checkbox = defineStyleConfig({
  variants,
  sizes,
  defaultProps: {
    variant: "primary",
    size: "xs",
  },
});
export default Checkbox;
