import { ChakraProvider } from "@chakra-ui/react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider } from "store";
import App from "./App";
import "./styles/font.css";
import "./styles/index.css";
import theme from "./theme";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StoreProvider>
    <ChakraProvider
      toastOptions={{ defaultOptions: { position: "top-right" } }}
      theme={theme}
    >
      <Router>
        <App />
      </Router>
    </ChakraProvider>
  </StoreProvider>
);
