import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Flex,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

const AppInput = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { label, showErrorMessage, errorMessage, sx, ...restProps } = props;
  return (
    <>
      {restProps.type === "password" ? (
        <Flex
          gap="4px"
          flexDirection="column"
          alignItems="start"
          width={"100%"}
        >
          {label && <HStack>
            <Text>{label} </Text>
            {restProps.required && <span style={{ color: "red" }}>*</span>}
          </HStack>}
          <InputGroup size="sm">
            <InputRightElement width="4.5rem">
              <IconButton
                aria-label="View password"
                onClick={handleClickShowPassword}
                icon={!showPassword ? <ViewIcon /> : <ViewOffIcon />}
              />
            </InputRightElement>
            <Input {...restProps} type={showPassword ? "text" : "password"} />
          </InputGroup>
        </Flex>
      ) : (
        <Flex
          gap="4px"
          flexDirection="column"
          alignItems="start"
          width={"100%"}
        >
          {label && <HStack>
            <Text>{label} </Text>
            {restProps.required && <span style={{ color: "red" }}>*</span>}
          </HStack>}
          <InputGroup size="sm">
            <Input {...restProps} type={restProps.type} />
          </InputGroup>
        </Flex>
      )}
      {showErrorMessage && (
        <FormErrorMessage style={{ color: "red" }}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </>
  );
};

export default AppInput;
