import { createContext, useReducer } from "react";
import { appLocalStorage } from "../utils/AppLocalStorage";
import { reducer } from "./reducer";

export const SecureStorageKeys = {
  darkMode: "ORIGIN360_darkMode",
  userProfile: "ORIGIN360_userProfile",
  language: "ORIGIN360_language",
  rememberMe: "ORIGIN360_rememberMe",
};

export const Store = createContext({} as any);
const currentTheme =
  appLocalStorage.getItem(SecureStorageKeys.darkMode) === "ON" ? true : false;
const defaultLanguage = appLocalStorage.getItem(SecureStorageKeys.language)
  ? appLocalStorage.getItem(SecureStorageKeys.language)
  : "en";
const user: any = appLocalStorage.getItem(SecureStorageKeys.userProfile)
  ? JSON.parse(appLocalStorage.getItem(SecureStorageKeys.userProfile) as string)
  : undefined;
const initialState = {
  activeTab: 0,
  filter: {
    category: "",
    noOfBedrooms: 0,
    noOfBathrooms: 0,
    city: "",
    maxPrice: 0,
  },
  dashboard: {
    openItem: ["dashboard"],
    defaultId: "dashboard",
    openComponent: "buttons",
    drawerOpen: false,
    componentDrawerOpen: false,
  },
  searchKeyword: "",
  lang: defaultLanguage,
  darkMode: currentTheme,
  user,
  toast: {
    show: false,
  },
};
export function StoreProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
