import { HStack, Modal, ModalContent, ModalOverlay, Spinner, Text } from "@chakra-ui/react";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

const LoadingModal = (props: any, ref: any) => {
  const [showModal, setShowModal] = useState(false);

  const open = useCallback(() => {
    setShowModal(true);
  }, []);
  const close = useCallback(() => {
    setShowModal(false);
  }, []);
  useImperativeHandle(ref, () => ({
    open: () => open(),
    close: () => close(),
  }));
  return (
    <Modal isOpen={showModal} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <HStack
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner size={"xl"} />
          <Text fontSize="30px">Loading</Text>
        </HStack>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(LoadingModal);
  