import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";
import { Credentials, User } from "typing";
import { loginSuccess, logoutUser } from "../actions/authentication";
import { Store } from "../store";
import { appLocalStorage } from "../utils/AppLocalStorage";
import { ADMIN, COMPANY } from "../utils/roles";

const TOKEN_EXPIRATION_TIME = 2; //in hours
const ACCESS_TOKEN = process.env.REACT_APP_SUCCOTH_API_ACCESS_TOKEN;
const API_BASE_URL = process.env.REACT_APP_SUCCOTH_API_BASE_URL;

export function useAuthentication() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useContext(Store);
  const { mutate } = useSWRConfig();

  const createAccessHeader = useCallback(
    async (apiKey?: string) => {
      try {
        const token = user ? user?.auth.access_token : ACCESS_TOKEN;
        return {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      } catch (error) {
        navigate("/");
        console.log(error);
      }
    },
    [navigate, user]
  );

  type sendRequestProps = {
    method: string;
    url: string;
    data?: any;
  };

  const sendRequest = useCallback(
    async ({ method, url, data }: sendRequestProps) => {
      return axios({
        url: url,
        method: method,
        headers: await createAccessHeader(),
        data: data,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (error.code === "ERR_CANCELED") {
            console.log("Request timed out");
          } else {
            console.log(error.message);
          }
        });
    },
    [createAccessHeader]
  );

  async function getCurrentAuthenticatedUser() {
    const response = appLocalStorage.getItem("userProfile");
    return response;
  }

  /**
   * SignIn
   */

  const saveUserInSession = useCallback(
    async (user: User) => {
      const tokenExpirationDate = new Date();
      tokenExpirationDate.setHours(
        tokenExpirationDate.getHours() + TOKEN_EXPIRATION_TIME
      );
      if (user) {
        user.auth.tokenExpirationDate = tokenExpirationDate;
        const tokenData = user;

        dispatch(loginSuccess(tokenData));
      }
    },
    [dispatch]
  );

  const signIn = useCallback(
    async ({ username, password }: Credentials) => {
      try {
        const result = await sendRequest({
          url: `${API_BASE_URL}/users/authenticate/`,
          data: { email: username, password },
          method: "post",
        });
        const response = await result;
        if (!response?.error_code && response?.message !== "confirm_email")
          saveUserInSession(response);
        setLoading(false);
        return response;
      } catch (err: any) {
        setLoading(false);
        // return err;
        console.log("ERRORRRRR", err);
      }
    },
    [saveUserInSession, sendRequest]
  );
  const signUp = useCallback(
    async (data: any) => {
      try {
        const result = await sendRequest({
          url: `${API_BASE_URL}/users/register/`,
          data,
          method: "post",
        });
        const response = await result;
        setLoading(false);
        return response;
      } catch (err) {
        setLoading(false);
        console.log("ERRORRRRR", err);
      }
    },
    [sendRequest]
  );
  const emailVerification = useCallback(
    async ({ token, otp }: { token: string; otp: string }) => {
      try {
        const result = sendRequest({
          url: `${API_BASE_URL}/users/verify-token/`,
          data: { token, otp },
          method: "post",
        });
        const response = await result;
        setLoading(false);
        return response;
      } catch (err) {
        setLoading(false);
        console.log("ERRORRRRR", err);
      }
    },
    [sendRequest]
  );
  const forgotPassword = useCallback(
    async ({ username }: { username: string }) => {
      try {
        const result = await sendRequest({
          url: `${API_BASE_URL}/users/password/request/`,
          data: { email: username },
          method: "post",
        });
        const response = await result;
        setLoading(false);
        return response;
      } catch (err) {
        setLoading(false);
        console.log("ERRORRRRR", err);
      }
    },
    [sendRequest]
  );

  const setNewPassword = useCallback(
    async ({ password, token }: { password: string; token: string }) => {
      try {
        const result = await sendRequest({
          url: `${API_BASE_URL}/users/password/set/`,
          data: { token, password },
          method: "post",
        });
        const response = await result;
        setLoading(false);
        return response;
      } catch (err) {
        setLoading(false);
        console.log("ERRORRRRR", err);
      }
    },
    [sendRequest]
  );

  /**
   * SignOut
   */

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const deleteLoggedInSession = useCallback(async () => {
    // appLocalStorage.clearStorage();
    clearCacheData();
  }, []);

  const signOut = useCallback(async () => {
    try {
      const authUser = user?.user;
      if (
        authUser?.roles?.includes(ADMIN) ||
        authUser?.roles?.includes(COMPANY)
      ) {
        navigate("/");
      } else {
        navigate("/");
      }
      

      mutate(
        (key) => true, // which cache keys are updated
        undefined, // update cache data to `undefined`
        { revalidate: false } // do not revalidate
      );
      dispatch(logoutUser());

      await deleteLoggedInSession();
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      console.log(ex);
    }
  }, [dispatch, navigate, deleteLoggedInSession, user, mutate]);

  const hasRole = useCallback(
    (role: string) => {
      setLoading(false);
      return user && user.roles && user.roles.includes(role);
    },
    // eslint-disable-next-line
    []
  );

  const hasPermission = useCallback(
    (model: string, method: string) => {
      let result = false;
      const permissions = user?.user?.app_permissions;
      permissions?.forEach((element: any) => {
        if (element.model === model && element.permission === method) {
          result = true;
        }
      });
      return result;
    },
    [user]
  );

  useEffect(() => {
    const tokenExipryDate = new Date(user?.auth?.tokenExpirationDate);
    const currentDate = new Date();
    if (tokenExipryDate.getTime() < currentDate.getTime()) {
      signOut();
    }
  }, [user, signOut]);

  return {
    loading,
    user: user as User,
    hasRole,
    hasPermission,
    authProvider: {
      signUp,
      signIn,
      signOut,
      getCurrentAuthenticatedUser,
      forgotPassword,
      setNewPassword,
      emailVerification,
    },
  };
}
