
import { AuthUser } from "typing";
import { SecureStorageKeys } from "../store";
import { appLocalStorage } from "../utils/AppLocalStorage";

export const SIGN_IN = {
  success: "UserAuthenticated",
  failure: "AuthenticationFailed",
  started: "AuthenticationStarted",
  logout: "LogoutUser",
  addRoles: "AddRoles",
};

export const AuthLoadingStatus = "Auth.Loading.Status";

export function loginSuccess(user: AuthUser) {
  appLocalStorage.setItem(SecureStorageKeys.userProfile, JSON.stringify(user));
  return {
    type: SIGN_IN.success,
    payload: user,
  };
}

export function loginFailure() {
  return {
    type: SIGN_IN.failure,
    payload: {},
  };
}

export function setAuthenticationLoadingStatus(status: boolean) {
  return {
    type: AuthLoadingStatus,
    payload: status,
  };
}

export function logoutUser() {
  appLocalStorage.setItem(SecureStorageKeys.userProfile, null);
  // localStorage.clear();
  // sessionStorage.clear();
  return {
    type: SIGN_IN.logout,
    payload: null,
  };
}
export function addUserRoles(roles: Array<string>) {
  return {
    type: SIGN_IN.addRoles,
    payload: roles,
  };
}

export function rememberMe(user: {username:string; password:string}) {
  appLocalStorage.setItem(SecureStorageKeys.rememberMe, JSON.stringify(user));
  return
}

export function getUserLoginDetails() {
  const user:any = appLocalStorage.getItem(SecureStorageKeys.rememberMe);
  return JSON.parse(user)
}
