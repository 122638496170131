import { actions } from "../actions";
import { SIGN_IN } from "../actions/authentication";

export function reducer(state: any, action: any) {
  switch (action?.type) {
    case actions.darkModeOn:
      return { ...state, darkMode: true };
    case actions.darkModeOff:
      return { ...state, darkMode: false };
    case actions.showToast:
      return {
        ...state,
        toast: action.payload,
      };
    case SIGN_IN.success:
      return {
        ...state,
        user: action.payload,
      };
    case SIGN_IN.logout:
      return {
        ...state,
        user: action.payload,
      };
    case actions.changeLanguage:
      return {
        ...state,
        lang: action.payload,
      };
    case actions.search:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case actions.filter:
      return {
        ...state,
        filter: { ...action.payload },
      };
    case actions.setActiveTab:
      return {
        ...state,
        activeTab: action.payload,
      };
    case actions.dashboard:
      return {
        ...state,
        dashboard: { ...action.payload },
      };
    default:
      return state;
  }
}
