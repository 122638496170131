import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { AppDropdownSelect } from "components/AppDropdownSelect";

import AppInput from "components/AppInput";
import { useFormik } from "formik";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "store";
import { showToast, stringifyErrors, toastTypes } from "utils";
import * as Yup from "yup";

export default function ManageSubCategoryPage() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const { getSubCategory, addSubCategory, updateSubCategory, getCategories } = useApi();
  const {
    data: subCategory,
    isLoading,
    mutate,
  } = useCache("subCategory", () => getSubCategory(+id || 0));

  const {
    data: categories,
  } = useCache("categories", () => getCategories());


  const initialValues = useMemo(() => {
    return {
      name: subCategory?.name || "",
      category: subCategory?.category_id || "",
      submit: null,
    };
  }, [subCategory]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Nom requis"),
    category: Yup.string().trim().required("Catégorie requis"),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const postData: any = {
          name: values.name,
          category_id: values.category,
        };
        let response: any = null;
        if (id) {
          response = await updateSubCategory({
            id: id,
            ...postData,
          });
        } else {
          response = await addSubCategory({
            ...postData,
          });
        }
        if (!response.error_code || response.errors) {
          dispatch(
            showToast({
              toast: toast,
              message: id
                ? "Sous-Catégorie mis à jour avec succès"
                : "Sous-Catégorie créé avec succès",
              status: toastTypes.success,
            })
          );
          if (id) {
            mutate(getSubCategory(+id));
            navigate("/sub-categories");
          } else {
            navigate(`/sub-categories`);
          }
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: response.errors
                ? stringifyErrors(response.errors)
                : response.message,
              status: toastTypes.error,
            })
          );
          setFieldValue("password", "");
        }
      } catch (ex) {
        dispatch(
          showToast({
            toast: toast,
            message: "Quelque chose s'est mal passé",
            status: toastTypes.error,
          })
        );
        setFieldValue("password", "");
      }
    },
  });
  return (
    <>
      {!isLoading && (
        <Flex
          gap="18px"
          bg="very_light_gray"
          flex={1}
          flexDirection="column"
          py="20px"
          px="30px"
        >
          <Box>
            <Flex gap="4px" flexDirection="column">
              <Flex justifyContent="center" alignItems="center">
                <Heading size="text2xl" as="h4" fontFamily="Arimo">
                  {id ? "Modifier catégorie" : "Créer une catégorie"}
                </Heading>
                <Flex
                  pl="56px"
                  pr="62px"
                  gap="9px"
                  flex={1}
                  justifyContent="flex-end"
                  px={{ md: 0, base: "20px" }}
                >
                  
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexDirection: "column",
              margin: "0px",
              alignItems: "center",
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex
                gap="16px"
                bg="white"
                boxShadow="xl"
                w="100%"
                flexDirection="column"
                px={{ base: "20px", sm: "24px" }}
                py={{ base: "20px", sm: "24px" }}
                mx={{ md: "62px", base: "0px" }}
                borderRadius="16px"
              >
                <Flex gap="16px" flexDirection="column">
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                    <Text color="gray_description">Nom</Text>
                    <FormControl
                      isInvalid={!!errors.name && (touched.name as boolean)}
                    >
                      <AppInput
                        required
                        placeholder={`Nom`}
                        borderWidth="1px"
                        alignSelf="stretch"
                        borderRadius="8px"
                        id="name"
                        name="name"
                        autoComplete="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name || initialValues.name}
                        showErrorMessage={Boolean(touched.name && errors.name)}
                        errorMessage={errors.name}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="4px" flexDirection="column" alignItems="start">
                  <Text color="gray_description">Catégorie</Text>
                  <Flex
                    gap="4px"
                    flexDirection="column"
                    alignItems="start"
                    width={"100%"}
                  >
                    <AppDropdownSelect
                      name="category"
                      data={categories}
                      value={values.category || initialValues.category}
                      onChange={setFieldValue}
                      placeholder="Veuillez sélectionner une sous-catégorie-"
                    />
                  </Flex>
                </Flex>
                </Flex>
              </Flex>
              <Flex mt="24px" gap="12px">
               <Button
                  size="4xl"
                  color="gray_description"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="106px"
                  borderRadius="8px"
                  onClick={() => window.history.back()}
                >
                  Annuler
                </Button>
                <Button
                  size="4xl"
                  colorScheme="red_origin"
                  fontFamily="Arimo"
                  fontWeight={700}
                  minW="132px"
                  borderRadius="8px"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Enregistrer
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
}
