import {
  Box,
  Input,
  InputGroup,
  List,
  ListItem,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

export type Props = {
  data: any[];
  onChange: any;
  name: string;
  value: string;
  placeholder: string;
  readonly?: boolean
};

export const AppDropdownSelect = ({ data, onChange, name, value, placeholder, readonly }: Props) => {
  const [filteredList, setFilteredList] = useState(data);
  const [selectedItem, setSelectedItem] = useState<any | undefined>("");
  const { isOpen, onToggle, onClose } = useDisclosure();


  useEffect(() => {
    if (value) {
      setSelectedItem(data?.find((item:any) => +item.id === +value)?.name)
    }
  }, [value, setSelectedItem, data]);

  const handleSearch = (event: any) => {
    const value = event.target.value.toLowerCase();
    const result: any =
      data?.filter((item: any) => {
        return item.name.toLowerCase().includes(value);
      }) || [];
    setFilteredList(result);
    setSelectedItem(value);
  };

  return (
    <Box
      // my={1}
      // maxH="xs"
      // bg="white"
      // width="full"
      // zIndex={999}
      // height="auto"
      // overflow="auto"
      // borderRadius="lg"
      // // position="absolute"
      // boxShadow="0px 1px 30px rgba(0, 0, 0, 0.1)"
      width={"100%"}
    >
      <InputGroup>
        <Input
          id={name}
          name={name}
          value={selectedItem}
          disabled={readonly||false}
          gap="8px"
          borderWidth="1px"
          alignSelf="stretch"
          borderRadius="8px"
          size="sm"
          type="search"
          autoComplete="off"
          placeholder={placeholder} // include place holder component
          onChange={(event) => handleSearch(event)}
          _focusWithin={{ borderColor: "secondary" }}
          _invalid={{ bg: "white", borderColor: "gray.50" }}
          onClick={() => {
            onToggle();
            setSelectedItem("");
            setFilteredList(data);
          }}
        />
      </InputGroup>
      {isOpen ? (
        <List borderWidth="1px" borderRadius="8px">
          {filteredList?.length ? (
            filteredList?.map((item: any, index: number) => (
              <ListItem
                key={index}
                paddingY={2}
                paddingX={2}
                color="#ACB9C4"
                cursor="pointer"
                fontWeight="500"
                textTransform="capitalize"
                onClick={(e: any) => {
                  onChange(name, item?.id);
                  setSelectedItem(item?.name);
                  onClose();
                }}
                style={{ transition: "all .125s ease" }}
                _hover={{ bg: "gray.50", color: "#396070" }}
                sx={
                  item?.id === selectedItem?.id
                    ? { backgroundColor: "gray.50", color: "#396070" }
                    : {}
                }
              >
                <Text as="span">{item?.name}</Text>
              </ListItem>
            ))
          ) : (
            <Text>No item to display</Text>
          )}
        </List>
      ) : null}
    </Box>
  );
};
