import useSWR from "swr";
export function useCache(key: any, fetcher: any) {
  const { data, error, isLoading, mutate } = useSWR(key, fetcher, { refreshInterval: 30000, revalidateIfStale: true });

  return {
    mutate,
    data,
    isLoading,
    isError: error,
  };
}
